import React from 'react';
export const quotes = [
    { id: 1, text: "The only limit to our realization of tomorrow is our doubts of today.", author: "Franklin D. Roosevelt" },
    { id: 2, text: "The purpose of our lives is to be happy.", author: "Dalai Lama" },
    { id: 3, text: "Life is what happens when you're busy making other plans.", author: "John Lennon" },
    { id: 4, text: "Get busy living or get busy dying.", author: "Stephen King" },
    { id: 5, text: "You have within you right now, everything you need to deal with whatever the world can throw at you.", author: "Brian Tracy" },
    { id: 6, text: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
    { id: 7, text: "The only impossible journey is the one you never begin.", author: "Tony Robbins" },
    { id: 8, text: "You must be the change you wish to see in the world.", author: "Mahatma Gandhi" },
    { id: 9, text: "Life is either a daring adventure or nothing at all.", author: "Helen Keller" },
    { id: 10, text: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
    { id: 11, text: "In the end, it's not the years in your life that count. It's the life in your years.", author: "Abraham Lincoln" },
    { id: 12, text: "The best way to predict the future is to create it.", author: "Peter Drucker" },
    { id: 13, text: "Don't watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
    { id: 14, text: "You miss 100% of the shots you don't take.", author: "Wayne Gretzky" },
    { id: 15, text: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
    { id: 16, text: "Hardships often prepare ordinary people for an extraordinary destiny.", author: "C.S. Lewis" },
    { id: 17, text: "The best revenge is massive success.", author: "Frank Sinatra" },
    { id: 18, text: "The only person you are destined to become is the person you decide to be.", author: "Ralph Waldo Emerson" },
    { id: 19, text: "It does not matter how slowly you go as long as you do not stop.", author: "Confucius" },
    { id: 20, text: "You are never too old to set another goal or to dream a new dream.", author: "C.S. Lewis" },
    { id: 21, text: "Everything you’ve ever wanted is on the other side of fear.", author: "George Addair" },
    { id: 22, text: "Opportunities don't happen. You create them.", author: "Chris Grosser" },
    { id: 23, text: "The only limit to our realization of tomorrow is our doubts of today.", author: "Franklin D. Roosevelt" },
    { id: 24, text: "Your time is limited, don't waste it living someone else's life.", author: "Steve Jobs" },
    { id: 25, text: "Dream big and dare to fail.", author: "Norman Vaughan" },
    { id: 26, text: "Everything you can imagine is real.", author: "Pablo Picasso" },
    { id: 27, text: "What lies behind us and what lies before us are tiny matters compared to what lies within us.", author: "Ralph Waldo Emerson" },
    { id: 28, text: "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.", author: "Ralph Waldo Emerson" },
    { id: 29, text: "I have not failed. I've just found 10,000 ways that won't work.", author: "Thomas Edison" },
    { id: 30, text: "Act as if what you do makes a difference. It does.", author: "William James" }
];


const QuoteComponent: React.FC = () => {
    // Function to get a random quote
    const getRandomQuote = () => {
        const randomIndex = Math.floor(Math.random() * quotes.length);
        return quotes[randomIndex];
    };

    const quote = getRandomQuote(); // Get a random quote when the component renders

    return (
        <div className="p-4 bg-gray-100 min-h-[150px] flex items-center justify-center ">
            <div className="bg-white p-6 rounded shadow-lg max-w-md w-full  justify-center overflow-hidden">
                <p className="text-lg italic">"{quote.text}"</p>
                <p className="mt-2 text-right font-semibold">- {quote.author}</p>
            </div>
        </div>
    );
};

export default QuoteComponent;
