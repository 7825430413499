import React, { useEffect, useState } from 'react';
import { useAuth } from '../components/AuthContext';
import StatisticsCard from '../components/StatisticsCard';
import StatisticsLineChart from '../components/StatisticsLineChart';
import { fetchOverallMemoStatistic, fetchUserMemoStatistic } from '../utils/api';
import QuoteComponent from '../components/QuoteComponent';

const Dashboard: React.FC = () => {
    const { user } = useAuth();
    const [userStats, setUserStats] = useState<{ inCount: number; outCount: number } | null>(null);
    const [userMonthlyStat, setUserMonthlyStat] = useState<
        {
            inCount: number;
            outCount: number;
            month: string;
        }[]>([]);
    const [overallStats, setOverallStats] = useState<{ inCount: number; outCount: number } | null>(null);
    const [overallMonthlyStat, setOverallMonthlyStat] = useState<
        {
            inCount: number;
            outCount: number;
            month: string;
        }[]>([]);
    const [isJawatanSpecific, setIsJawatanSpecific] = useState<boolean>(false);
    const [error, setError] = useState<String>('');
    const [isFetchUserStatisticError, setIsFetchUserStatisticError,] = useState<boolean>(false);
    const [isFetchOverallStatisticError, setIsFetchOverallStatisticError,] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [fetchingUserMemoStatistic, setFetchingUserMemoStatistic] = useState<boolean>(true);
    const [fetchingOverallMemoStatistic, setFetchingOverallMemoStatistic] = useState<boolean>(true);
    const [retry, setRetry] = useState<boolean>(false);


    const fetchUserMemoStatistics = async () => {
        try {
            setFetchingUserMemoStatistic(true);
            const response = await fetchUserMemoStatistic(user?.id!);
            console.log("API Response:", response); // Debugging the response
            
            // Get current month and year
            const currentDate = new Date();
            const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' }).toUpperCase(); // "JAN", "FEB", etc.
            const currentYear = currentDate.getFullYear().toString(); // Current year as string
    
            // Assuming response.monthlyData is an array
            const currentMonthData = response.monthlyData.find(
                (stat: any) => stat.month === currentMonth && stat.year === currentYear
            );
    
            if (currentMonthData) {
                const totalInCount = currentMonthData.inCount;
                const totalOutCount = currentMonthData.outCount;
                console.log(`Total In Count for ${currentMonth} ${currentYear}:`, totalInCount);
                console.log(`Total Out Count for ${currentMonth} ${currentYear}:`, totalOutCount);

                setUserStats({inCount: totalInCount, outCount: totalOutCount});
            
                // Set this data in your state if necessary
            } else {
                console.log("No data found for the current month and year.");
            }
    
            setUserMonthlyStat(response.monthlyData);
            setIsFetchUserStatisticError(false);
        } catch (error) {
            console.error("Error fetching user statistics:", error);
            setIsFetchUserStatisticError(true);
        } finally {
            setFetchingUserMemoStatistic(false);
        }
    };
    


    const fetchOverallMemoStatistics = async () => {
        try {
            setFetchingOverallMemoStatistic(true); // Indicate fetching in progress
            const response = await fetchOverallMemoStatistic(); // Fetch overall memo statistics
    
            console.log("API Response:", response); // Debugging the response
            
            // Get current month and year
            const currentDate = new Date();
            const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' }).toUpperCase(); // "JAN", "FEB", etc.
            const currentYear = currentDate.getFullYear().toString(); // Current year as string
    
            // Assuming response.monthlyData is an array
            const currentMonthData = response.monthlyData.find(
                (stat: any) => stat.month === currentMonth && stat.year === currentYear
            );
    
            if (currentMonthData) {
                const totalInCount = currentMonthData.inCount;
                const totalOutCount = currentMonthData.outCount;
                console.log(`Total In Count for ${currentMonth} ${currentYear}:`, totalInCount);
                console.log(`Total Out Count for ${currentMonth} ${currentYear}:`, totalOutCount);
    
                setOverallStats({ inCount: totalInCount, outCount: totalOutCount }); // Update overall stats with current month data
            } else {
                console.log("No data found for the current month and year.");
            }
    
            // Optionally, set the full monthly data if required
            setOverallMonthlyStat(response.monthlyData);
        } catch (error: any) {
            setError('Failed to fetch statistics');
            console.log("Error:", error);
            setIsFetchOverallStatisticError(true);
        } finally {
            setFetchingOverallMemoStatistic(false); // Reset the fetching state
        }
    };
    

    useEffect(() => {
        if (['ADMIN', 'SUPER-ADMIN', 'SYSTEM-ADMIN'].includes(user?.role!) || ['KPPK', 'TKPPK'].includes(user?.kod_jawatan!)) {
            setIsJawatanSpecific(true);
        }
    }, [isJawatanSpecific]);

    useEffect(() => {
        fetchUserMemoStatistics();
    }, []);

    useEffect(() => {
        fetchOverallMemoStatistics();
    }, []);

    const handleUserRetry = () => {
        fetchUserMemoStatistics();
    }

    const handleOverallRetry = () => {
        fetchOverallMemoStatistics();
    }

    // useEffect(() => {
    //     const fetchStats = async () => {
    //         const jawatan = user?.kod_jawatan || 'guest';
    //         const isJawatanView = ['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'KPPK', 'TKPPK'].includes(jawatan);
    //         setIsJawatanSpecific(isJawatanView);
    //     };

    //     fetchStats();
    // }, [user]);


    // if (loading) return (
    //     <div className='flex flex-1 items-center justify-center min-h-full flex-col'>
    //         {/* Loading Animation Container */}
    //         <div className='flex items-center justify-center h-[200px] w-[200px] mb-4'>
    //             <LoadingStatisticalLottieAnimation width={200} height={200} />
    //         </div>
    //         {/* Loading Message */}
    //         <p className='text-lg text-gray-600 font-semibold'>
    //             Please wait...
    //         </p>
    //     </div>
    // )

    // if (error) return (
    //     <>
    //         <div className='flex flex-1 items-center justify-center min-h-full flex-col'>
    //             {/* Loading Animation Container */}
    //             <div className='flex items-center justify-center h-[200px] w-[200px] mb-4'>
    //                 <ErrorLottieAnimation width={200} height={200} />
    //             </div>
    //             {/* Loading Message */}
    //             <p className='text-lg text-gray-600 font-semibold'>
    //                 {error}
    //             </p>
    //         </div>
    //     </>
    // );



    return (
        <div className="w-full sm:mx-0 md:mx-4">
            <h1 className="text-lg font-bold mb-2">Dashboard</h1>

            <div>
                <QuoteComponent />
            </div>

            {/* User-Specific Statistics Section */}
            <div className="bg-gray-100 p-4 sm:p-6 mb-4 rounded-lg shadow-md">
                <h2 className="text-lg font-semibold mb-4">My Statistics [{user?.nama_pendek}]</h2>

                {/* Grid Container for Statistics */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                    {/* Second and Third Statistics Cards - Side by Side */}
                    <div className="col-span-1">
                        <div className='flex flex-1 bg-white'>
                            <StatisticsCard
                                title="Memos IN"
                                value={userStats? userStats.inCount: 'XX'}
                                bgColor="bg-red-100"
                                isLoading={fetchingUserMemoStatistic}
                                isError={isFetchUserStatisticError}
                                handleError={handleUserRetry}
                                className="bg-white rounded-xl w-full h-[100px] justify-center"
                            />
                        </div>
                    </div>


                    {/* Second and Third Statistics Cards - Side by Side */}
                    <div className="col-span-1">
                        <div className='flex flex-1 bg-white'>
                            <StatisticsCard
                                title="Memos OUT"
                                value={userStats?.outCount ?? 'XX'}
                                //value={userStats? userStats.inCount: 'XX'}
                                bgColor="bg-red-100"
                                isLoading={fetchingUserMemoStatistic}
                                isError={isFetchUserStatisticError}
                                handleError={handleUserRetry}
                                className="bg-white rounded-xl w-full h-[100px] justify-center"
                            />
                        </div>
                    </div>

                    {/* Full Width Line Chart Below the Two Columns */}
                    <div className="col-span-1 md:col-span-2 mt-6">
                        <StatisticsLineChart handleError={handleUserRetry} isError={isFetchUserStatisticError} isLoading={fetchingUserMemoStatistic} title="Your Memos Count - Last 12 Months" chart_data={userMonthlyStat} />
                    </div>
                </div>
            </div>

            {/* Overall Statistics Section (for roles KPPK and TKPPK) */}
            {isJawatanSpecific && (
                <>
                    {/* User-Specific Statistics Section */}
                    <div className="bg-gray-100 p-4 sm:p-6 mb-4 rounded-lg shadow-md">
                        <h2 className="text-lg font-semibold mb-4">Overall Statistic.</h2>

                        {/* Grid Container for Statistics */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                            {/* Second and Third Statistics Cards - Side by Side */}
                            <div className="col-span-1">
                                <div className='flex flex-1 bg-white'>
                                    <StatisticsCard
                                        title="Memos IN"
                                        value={overallStats?.inCount ?? 'XX'}
                                        bgColor="bg-red-100"
                                        isLoading={fetchingOverallMemoStatistic}
                                        isError={isFetchOverallStatisticError}
                                        handleError={handleOverallRetry}
                                        className="bg-white rounded-xl w-full h-[100px] justify-center"
                                    />
                                </div>
                            </div>


                            {/* Second and Third Statistics Cards - Side by Side */}
                            <div className="col-span-1">
                                <div className='flex flex-1 bg-white'>
                                    <StatisticsCard
                                        title="Memos OUT"
                                        value={overallStats?.outCount ?? 'XX'}
                                        bgColor="bg-red-100"
                                        isLoading={fetchingOverallMemoStatistic}
                                        isError={isFetchOverallStatisticError}
                                        handleError={handleOverallRetry}
                                        className="bg-white rounded-xl w-full h-[100px] justify-center"
                                    />
                                </div>
                            </div>

                            {/* Full Width Line Chart Below the Two Columns */}
                            <div className="col-span-1 md:col-span-2 mt-6">
                                <StatisticsLineChart handleError={handleOverallRetry} isError={isFetchOverallStatisticError} isLoading={fetchingOverallMemoStatistic} title="Your Memos Count - Last 12 Months" chart_data={overallMonthlyStat} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Dashboard;
