import React from 'react';
import { User } from '../types/user';
import NoProfilePictureLottieAnimationGirl from './NoProfilePictureLottieAnimationGirl';
import NoProfilePictureLottieAnimationBoy from './NoProfilePictureLottieAnimationBoy';
import { useNavigate } from 'react-router-dom';

// Define darker gradient and border color mappings
const gradientMap = {
    'KPPK': 'from-orange-700 via-orange-850 to-orange-900', // Darker gradient for KPPK
    'KPP': 'from-orange-500 via-orange-600 to-orange-700', // Darker gradient for KPP
    'PP': 'from-green-700 via-green-800 to-green-900',     // Darker gradient for PP
    'PT': 'from-blue-600 via-blue-650 to-blue-700'         // Darker gradient for PT
};

const borderColorMap = {
    'KPPK': 'border-orange-600', // Darker border color for KPPK
    'KPP': 'border-orange-500',  // Darker border color for KPP
    'PP': 'border-green-800',    // Darker border color for PP
    'PT': 'border-blue-600'      // Darker border color for PT
};

interface SenaraiPenggunaRowCardProps {
    user: User;
}

const SenaraiPenggunaRowCard: React.FC<SenaraiPenggunaRowCardProps> = ({ user }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/admin/edituser/${user.id}`);
    };

    // Determine the gradient and border classes based on kod_jawatan
    const gradientClass = gradientMap[user.kod_jawatan!] || 'from-gray-400 via-gray-500 to-gray-600';
    const borderColorClass = borderColorMap[user.kod_jawatan!] || 'border-gray-400';

    return (
        <div
            className={`relative border ${borderColorClass} bg-gradient-to-r ${gradientClass} rounded-lg shadow-lg flex flex-col w-full h-full cursor-pointer overflow-hidden`}
            onClick={handleClick}
        >
            {/* Content Wrapper */}
            <div className="relative z-10 p-4 flex flex-col h-full">
                {/* Profile Image */}
                <div className="hidden md:flex lg: justify-center mb-4">
                    {user.profile_image ? (
                        <img 
                            src={user.profile_image}
                            alt="Profile"
                            className="rounded-full sm:hidden object-cover w-24 h-24" // Adjust size as needed
                        />
                    ) : (
                        (user.jantina === "P" ? (
                            <div className="hidden md:flex lg:flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full">
                                <NoProfilePictureLottieAnimationGirl height={120} width={120} />
                            </div>
                        ) : (
                            <div className="hidden md:flex lg:flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full">
                                <NoProfilePictureLottieAnimationBoy height={120} width={120} />
                            </div>
                        )
                        )
                    )}
                </div>

                {/* User Info */}
                <div className="text-center flex-grow flex flex-col justify-between">
                    {/* User Name */}
                    <h3 className="text-sm font-semibold mb-1 text-white text-glow">{user.nama}</h3>

                    {/* User Info at the bottom */}
                    <div className="mt-auto">
                        <p className="text-[11px] text-gray-700 mb-2">
                            <span className='bg-rose-600 text-white rounded shadow px-2 py-1 mr-1'>{user.nokp}</span>
                        </p>
                        <div className="text-[11px] text-gray-700 mb-1">
                            <span className='bg-orange-600 text-white rounded shadow px-2 py-1 mr-1'>{user.kod_jawatan}</span>
                            <span className='bg-blue-600 text-white rounded shadow px-2 py-1'>{user.gred_jawatan}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SenaraiPenggunaRowCard;
