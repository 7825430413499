import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext'; // Adjust the import path if necessary

const AdminPage: React.FC = () => {
    const { user, isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("isAuthenticated", isAuthenticated);
        console.log("User.Role", user?.role);
        if (!isAuthenticated) {
            // Redirect to login page if not authenticated
            
        }
    }, [isAuthenticated, navigate]);

    if (!isAuthenticated) {
        // While waiting for redirection, you might show a loading spinner or message
        return <p>Redirecting...</p>;
    }

    if(user?.role == null){
        return <div>User Role: Empty</div>
    }
    if (!['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN'].includes(user?.role)) {
    
        return <p>You do not have permission to view this page.</p>;
    }

    return (
        <div>
            <h1>Admin Page</h1>
        </div>
    );
};

export default AdminPage;
