import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEdit, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { getMemoById, getUserByShortName, updateMemo } from '../utils/api';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

// Spinner component (can be placed in a separate file)
const Spinner = () => (
    <div className="flex justify-center items-center">
        <div className="w-6 h-6 border-4 border-t-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
    </div>
);

interface UserOption {
    label: string;
    value: number;
}

const formatDateForInput = (date: Date) => {
    if (!date) return '';

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export default function EditMemoPage() {
    const { memoId } = useParams<{ memoId: string }>(); // Get memoId from URL params
    const [title, setTitle] = useState('');
    const [receivedDate, setReceivedDate] = useState('');
    const [actionDate, setActionDate] = useState('');
    const [memoType, setMemoType] = useState('IN');
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState(''); // State to hold the file name
    const [users, setUsers] = useState<UserOption[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UserOption[]>([]);
    const [searchParam, setSearchParam] = useState('');
    const [loading, setLoading] = useState(false);
    const [getting, setGetting] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [memo, setMemo] = useState<any | null>(null); // Memo data

    useEffect(() => {
        const fetchMemo = async () => {
            try {
                setLoading(true);
                const memo_id: number = Number(memoId);
                //console.log("MEMO_ID", memo_id);
                const response = await getMemoById(memo_id);
                setMemo(response);
                setTitle(response.tajuk);
                setReceivedDate(formatDateForInput(response.tarikh_terima));
                setActionDate(formatDateForInput(response.tarikh_tindakan));
                setSelectedUsers(response.recipients.map((user: any) => ({
                    label: user.nama_pendek,
                    value: user.id_pengguna,
                })));
                setFileName(response.nama_file_asal ? response.nama_file_asal.split('/').pop() || '' : ''); // Extract file name
            } catch (error) {
                console.error("Failed to fetch memo", error);
                toast.error('Failed to fetch memo');
            } finally {
                setLoading(false);
            }
        };

        if (memoId) {
            fetchMemo();
        }
    }, [memoId]);

    // Fetch users from the API and set the options for the multi-select
    useEffect(() => {
        const fetchUsers = async () => {
            if (!searchParam) return;
            if (searchParam.length < 3) return;

            setGetting(true); // Set loading to true when starting to fetch
            try {
                const response = await getUserByShortName(searchParam);
                const userOptions = response.map((user: any) => ({
                    label: user.s_name,
                    value: user.id,
                }));
                setUsers(userOptions);
            } catch (error) {
                console.error("Failed to fetch users", error);
            } finally {
                setGetting(false); // Set loading to false when fetching is complete
            }
        };

        fetchUsers();
    }, [searchParam]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitting(true);

        const memoData = {
            tajuk: title,
            tarikh_terima: receivedDate,
            tarikh_tindakan: actionDate,
            jenis_memo: memoType
        };

        const recipients = selectedUsers.map(user => ({
            id_pengguna: user.value,
        }));

        try {
            const memo_id: number = Number(memoId);
            const response = await updateMemo(memo_id, memoData, recipients);
            console.log('Memo updated successfully', response);
            toast.success('Memo updated successfully');
            // Reset form fields
            setTitle('');
            setReceivedDate('');
            setActionDate('');
            setMemoType('');
            setSelectedUsers([]);
        } catch (error) {
            console.error('Failed to update memo', error);
            toast.error('Failed to update memo');
        } finally {
            setSubmitting(false);


            //setMemoId(null); // or setMemoId(null) if memoId can be null
        }
    };


    if (!memo) return <div className="flex justify-center items-center min-h-full">Nothing to view.</div>;
    if (loading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;

    return (
        <div className="flex flex-1 justify-center items-center min-h-full">
            <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg bg-white p-6 rounded-md shadow-md space-y-4"
            >
                <div className="text-2xl font-semibold mb-4 bg-blue-500 text-white p-10 m-[-1.5rem] rounded-t-lg">
                    <h5>Edit Memo</h5>
                </div>


                {/* Title input */}
                <div className="relative">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">Tajuk</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <textarea
                            id="title"
                            rows={8}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Received date input */}
                <div className="relative">
                    <label htmlFor="receivedDate" className="block text-sm font-medium text-gray-700">Tarikh Terima</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="receivedDate"
                            type="date"
                            value={receivedDate}
                            onChange={(e) => setReceivedDate(e.target.value)}
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Action date input */}
                <div className="relative">
                    <label htmlFor="actionDate" className="block text-sm font-medium text-gray-700">Tarikh Tindakan</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="actionDate"
                            type="date"
                            value={actionDate}
                            onChange={(e) => setActionDate(e.target.value)}
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Memo Type */}
                <div className="mb-4">
                    <span className="block text-gray-700">Jenis Memo</span>
                    <div className="mt-2 flex flex-col space-y-2">
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="radio"
                                name="memoType"
                                value="IN"
                                checked={memoType === 'IN'}
                                onChange={() => setMemoType('IN')}
                                className="form-radio h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                disabled={submitting}
                            />
                            <span className="ml-2 text-gray-700">Masuk</span>
                        </label>
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="radio"
                                name="memoType"
                                value="OUT"
                                checked={memoType === 'OUT'}
                                onChange={() => setMemoType('OUT')}
                                className="form-radio h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                disabled={submitting}
                            />
                            <span className="ml-2 text-gray-700">Keluar</span>
                        </label>
                    </div>
                </div>

                {/* Select users */}
                <div className="relative">
                    <label htmlFor="users" className="block text-sm font-medium text-gray-700">Select Users</label>
                    <div className="relative">
                        <Select
                            id="users"
                            isMulti
                            options={users}
                            value={selectedUsers}
                            onChange={(selectedOptions) => setSelectedUsers(selectedOptions as UserOption[])}
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Select users..."
                            onInputChange={(inputValue) => setSearchParam(inputValue)}
                            isLoading={getting}
                            noOptionsMessage={() => (loading ? "Loading..." : "No options")}
                            isDisabled={submitting} // Disable while submitting
                        />
                    </div>
                </div>

                {/* File name display (non-editable, multiline) */}
                <div className="relative">
                    <label htmlFor="fileName" className="block text-sm font-medium text-gray-700">File</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <textarea
                            id="fileName"
                            value={fileName}
                            readOnly
                            rows={3} // Adjust rows to fit the expected content size
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 text-gray-700 cursor-not-allowed sm:text-sm resize-none"
                        />
                    </div>
                </div>

                {/* Submit button */}
                <div className="mt-6">
                    <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={submitting}
                    >
                        {submitting ? <Spinner /> : 'Update Memo'}
                    </button>
                </div>
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}


