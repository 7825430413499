import React, { useEffect, useState } from 'react';
import { getUsers, deleteUser } from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SenaraiPenggunaRowCard from '../components/SenaraiPenggunaRowCard';
import { User } from '../types/user';
import LoadingProfileAnimation from '../components/LoadingProfileAnimation';
import ErrorLottieAnimation from '../components/ErrorLottieAnimation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function ViewSenaraiPenggunaPage() {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getUsers();
                setUsers(response.users);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('Something went wrong!');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleDelete = async (userId: number) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this user?');
        if (confirmDelete) {
            setDeleting(userId);
            try {
                await deleteUser(userId);
                setUsers(users.filter(user => user.id !== userId));
                toast.success('User deleted successfully!');
            } catch {
                toast.error('Failed to delete user.');
            } finally {
                setDeleting(null);
            }
        }
    };

    if (loading) return (
        <div className='flex flex-1 items-center justify-center min-h-full flex-col'>

            {/* Loading Animation Container */}
            <div className='flex items-center justify-center h-[200px] w-[200px] mb-4'>
                <LoadingProfileAnimation width={200} height={200} />
            </div>
            {/* Loading Message */}
            <p className='text-lg text-gray-600 font-semibold'>
                Please wait...
            </p>
        </div>
    );

    if (error) return (
        <div className="flex items-center justify-center min-h-full flex-col">
            <ErrorLottieAnimation width={250} height={250} />
            <p className="text-lg text-gray-600 font-semibold">{error}</p>
        </div>
    );

    return (
        <div className="p-2 sm:p-0">
            {/* Title outside the search box and left-aligned */}
            <h1 className="text-2xl font-bold mb-4 text-left text-gray-900" style={{ fontFamily: 'CustomFont, sans-serif' }}>
                User List
            </h1>


            {/* Search Section */}
            <div className="w-full  max-w-md mx-auto mb-6">
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by name..."
                        className="border border-gray-300 rounded-l-full py-3 pl-4 w-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button className="border-r border-y border-gray-300 bg-blue-500 hover:bg-blue-600 shadow-md text-white px-6 py-3 rounded-r-full h-full focus:outline-none">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
            </div>

            {/* User List Section */}
            <div className="w-full mx-auto border border-gray-200 rounded-lg py-4 sm:px-0 shadow-lg">
                <div className="flex flex-wrap sm:gap-6 md:gap-2 lg:gap-2 justify-center">
                    {users.length > 0 ? (
                        users.map(user => (
                            <div
                                className="w-[98%] mb-2 lg:mb-0 md:mb-0 sm:w-[98%] md:min-w-[180px] md:max-w-[180px] lg:min-w-[180px] lg:max-w-[180px]"
                                key={user.id}
                            >
                                <SenaraiPenggunaRowCard user={user} />
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-600">No users found.</p>
                    )}
                </div>
            </div>

            <ToastContainer />
        </div>
    );
}
