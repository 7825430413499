import React from 'react';
import { useAuth } from './AuthContext'; // Ensure this path is correct

const Footer: React.FC = () => {
    const { isAuthenticated } = useAuth();

    if(!isAuthenticated) return null;
    return (
        <footer className="bg-gray-800 text-white p-4 text-center">
            <p>&copy; {new Date().getFullYear()} {}. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
