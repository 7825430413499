import React from 'react';
import { useAuth } from '../components/AuthContext';

const UserPage = () => {
    const { user, isAuthenticated, logout } = useAuth();

    if (['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'USER'].includes(user?.role!)) {
        return <p>You do not have permission to view this page.</p>;
    }

    return (
        <div>
            <h1>User Page</h1>
            <button onClick={logout}>Logout</button>
        </div>
    );
};

export default UserPage;
