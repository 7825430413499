import React, { useEffect, useRef } from 'react';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LineChartProps {
    chartData: any;
    title: string;
}

const LineChart: React.FC<LineChartProps> = ({ chartData, title }) => {
    const chartRef = useRef<any>(null);

    useEffect(() => {
        // Cleanup the chart on unmount to prevent canvas reuse issues
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, []);

    return (
        <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">{title}</h2>
            {chartData ? (
                <Line ref={chartRef} data={chartData} />
            ) : (
                <p>Loading chart...</p>
            )}
        </div>
    );
};

export default LineChart;
