import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../static/loading-statistical-data.json'; // Replace with the path to your animation JSON file

interface LoadingLottieAnimationProps {
    height: number;
    width: number
}

const LoadingStatisticalLottieAnimation: React.FC<LoadingLottieAnimationProps> = ({ height, width }) => {
    return (
        <div className='flex justify-center items-center h-full'>
            <Player
                autoplay
                loop
                src={animationData}
                style={{ height: `${height}px`, width: `${width}px` }}
            />
        </div>
    );
};

export default LoadingStatisticalLottieAnimation;
