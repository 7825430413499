import React, { useEffect, useState } from 'react';
import LineChart from './LineChart';
import ErrorLottieAnimation from './ErrorLottieAnimation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import LoadingStatisticalLottieAnimation from './LoadingStatisticalLottieAnimation';


interface StatisticsLineChartProps {
    isLoading: boolean;
    isError: boolean;
    title: string;
    chart_data: StatisticData[];
    className?: string;
    handleError?: () => void;
    handleRefresh?: () => {};
}

interface StatisticData {
    inCount: number;
    outCount: number;
    month: string;
}

const StatisticsLineChart: React.FC<StatisticsLineChartProps> = ({ isLoading, isError, handleError, title, chart_data, className }) => {
    const [chartData, setChartData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    const populateChartData = () => {

        //console.log("::::::::::;", chart_data);
        // Extract labels (months) and in/out counts
        const labels = chart_data.map((item) => item.month);
        const inCount = chart_data.map((item) => Number(item.inCount));
        const outCount = chart_data.map((item) => Number(item.outCount));

        // Compute totalCount as the sum of inCount and outCount for each month
        const totalCount = inCount.map((inValue, index) => inValue + outCount[index]);

        setChartData({
            title: title,

            labels: labels,
            datasets: [
                {
                    label: 'In Count',
                    data: inCount,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                },
                {
                    label: 'Out Count',
                    data: outCount,
                    borderColor: 'rgba(153, 102, 255, 1)',
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                },
                {
                    label: 'Total Count',
                    data: totalCount,
                    borderColor: 'rgba(255, 159, 64, 1)',
                    backgroundColor: 'rgba(255, 159, 64, 0.2)',
                },
            ],
        });
        setLoading(false);
    }



    useEffect(() => {
        populateChartData();
    }, [chart_data]);

 
    if (isLoading) return (
        <div className={`${className}`}>
            <LoadingStatisticalLottieAnimation width={50} height={50} />
        </div>
    )

    if (isError) return (
        <>
            <div className='flex flex-1 items-center justify-center min-h-full flex-col'
            onClick={handleError}>
                {/* Loading Animation Container */}
                <div className='flex flex-col items-center justify-center h-[200px] w-[200px] mb-4 bg-white rounded-2xl'>
                    {/* Icon */}
                    <FontAwesomeIcon icon={faRefresh} className='text-3xl text-gray-600 mb-2' />
                    {/* Retry Text */}
                    <p className="text-gray-600">Tap/Click to retry</p>
                </div>
            </div>
        </>
    );

    return (
        <LineChart chartData={chartData} title="Statistics Overview" />
    )
};

export default StatisticsLineChart;
