import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faEdit, faTrash, faSpinner, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { fetchFileBlob } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { Memo } from '../types/Memo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MemoModal from './MemoModal';

interface ViewMemoRowProps {
    memo: Memo;
    onDelete: (id: number) => Promise<void>;
}

const ViewMemoRowComponent: React.FC<ViewMemoRowProps> = ({ memo, onDelete }) => {
    const [blobUrls, setBlobUrls] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const navigate = useNavigate();

    const handleDelete = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete this memo?');
        if (confirmDelete) {
            try {
                await onDelete(memo.id!);
                toast.success('Memo deleted successfully!');
            } catch (error) {
                toast.error('Failed to delete memo.');
            }
        }
    };

    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            setError(null);

            try {
                // Fetch the image blob for only the URL at index 0
                if (memo.png_urls.length > 0) {
                    const url = memo.png_urls[0];
                    const blobUrl = await fetchFileBlob(url);
                    setBlobUrls([blobUrl]); // Update state with just the single blob URL
                }
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message)

                }
                setError('Failed to load images');
            } finally {
                setLoading(false);
            }
        };

        fetchImages();

        return () => {
            // Revoke the blob URL when the component is unmounted or the URL changes
            blobUrls.forEach((url) => URL.revokeObjectURL(url));
            setBlobUrls([]);
        };
    }, [memo.png_urls]); // Depend on the entire array, but only use the first URL

    const openModal = () => {
        setCurrentIndex(0); // Reset to the first image in the modal
        setModalOpen(true);
    };

    const handleNext = () => {
        if (currentIndex < memo.png_urls.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const closeModal = () => {
        setModalOpen(false); // Close the modal
    };

    const handleDownloadPdf = async () => {
        try {
            // Fetch the PDF Blob from the server using the memo.pdf_url
            const blobUrl = await fetchFileBlob(memo.pdf_url);

            // Create a temporary anchor element to download the PDF
            const a = document.createElement('a');
            a.href = blobUrl; // Set the Blob URL
            a.download = 'memo.pdf'; // The name of the file to be downloaded (you can change the file name)

            // Trigger the download
            document.body.appendChild(a);
            a.click();

            // Clean up by removing the anchor element and revoking the Blob URL
            document.body.removeChild(a);
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Failed to download PDF', error);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row bg-white border rounded-lg p-4 mb-4 shadow-md">
            {/* Column 1: Image with reduced width */}
            <div className="hidden lg:flex lg:w-1/4 justify-center items-center">
                {loading ? (
                    <div className="flex items-center justify-center h-64 w-full bg-gray-200 rounded-lg">
                        <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                    </div>
                ) : blobUrls[0] ? (
                    <img
                        src={blobUrls[0]}
                        alt="Memo preview"
                        className="rounded-lg object-cover max-h-64 cursor-pointer"
                        onClick={openModal} // Open modal when clicking the image
                    />
                ) : (
                    <div className="flex items-center justify-center h-64 w-full bg-gray-200 rounded-lg">
                        <span className="text-gray-500">No image available</span>
                    </div>
                )}
            </div>


            {/* Column 2: Info and Actions */}
            <div className="w-full lg:w-3/4 flex flex-col justify-between ml-0 lg:ml-4 mt-4 lg:mt-0">
                {/* Title and Recipients */}
                <div className="flex-1 flex flex-col justify-between">
                    {/* Title */}
                    <div className="flex-1 text-black-900 mb-2 overflow-hidden text-ellipsis">
                        {memo.tajuk}
                    </div>

                    {/* Recipients - anchored at the bottom */}
                    <div className="flex-none flex flex-wrap mb-4">
                        {memo.recipients.map((recipient) => (
                            <span key={recipient.id_pengguna} className="bg-blue-600 mr-2 text-white px-2 py-1 rounded">
                                {recipient.nama_pendek}
                            </span>
                        ))}
                    </div>

                    {/* Dates */}
                    <div className="flex flex-wrap text-sm">
                        <span className="bg-green-600 mr-2 mb-2 text-white px-2 py-1 rounded">
                            Terima: {new Date(memo.tarikh_terima).toLocaleDateString()}
                        </span>
                        <span className="bg-red-600 mr-2 mb-2 text-white px-2 py-1 rounded">
                            Tindakan: {new Date(memo.tarikh_tindakan).toLocaleDateString()}
                        </span>
                        <span className="bg-emerald-500 mr-2 mb-2 text-white px-2 py-1 rounded">
                            Tambah: {new Date(memo.tarikh_tambah).toLocaleDateString()}
                        </span>
                        {memo.tarikh_kemaskini && (
                            <span className="bg-orange-600 mr-2 mb-2 text-white px-2 py-1 rounded">
                                Kemaskini: {new Date(memo.tarikh_kemaskini).toLocaleDateString()}
                            </span>
                        )}
                    </div>

                </div>

                {/* Actions Row with equal width buttons */}
                <div className="grid grid-cols-4 gap-4 w-full mt-4">
                    {/* View Images Button */}
                    <div className="flex flex-col items-center">
                        <button
                            className="bg-blue-500 text-white border border-blue-500 hover:bg-blue-600 flex items-center justify-center w-full py-2 rounded"
                            onClick={openModal}
                        >
                            <FontAwesomeIcon icon={faImage} size="lg" />
                        </button>
                    </div>

                    {/* Edit Memo Button */}
                    <div className="flex flex-col items-center">
                        <button
                            className="bg-yellow-500 text-white border border-yellow-500 hover:bg-yellow-600 flex items-center justify-center w-full py-2 rounded"
                            onClick={() => navigate(`/editmemo/${memo.id}`)}
                        >
                            <FontAwesomeIcon icon={faEdit} size="lg" />
                        </button>
                    </div>

                    {/* Delete Memo Button */}
                    <div className="flex flex-col items-center">
                        <button
                            className="bg-red-500 text-white border border-red-500 hover:bg-red-600 flex items-center justify-center w-full py-2 rounded"
                            onClick={handleDelete}
                        >
                            <FontAwesomeIcon icon={faTrash} size="lg" />
                        </button>
                    </div>

                    {/* Additional Button Example */}
                    <div className="flex flex-col items-center">
                        <button 
                            onClick={handleDownloadPdf}
                            className="bg-green-500 text-white border border-green-500 hover:bg-green-600 flex items-center justify-center w-full py-2 rounded"
                        >
                            <FontAwesomeIcon icon={faFilePdf} size="lg" />
                        </button>
                    </div>
                </div>
            </div>

            {/* Image Preview Modal */}
            {/* Modal component */}
            {isModalOpen && (
                <MemoModal
                    onClose={closeModal}
                    imageUrls={memo.png_urls}
                    currentIndex={currentIndex}
                    onNext={handleNext}
                    onPrev={handlePrev}
                />
            )}
        </div >
    );
};

export default ViewMemoRowComponent;
