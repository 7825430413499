import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { User } from '../types/user'; // Adjust the import path as needed
import { loginAPI, refreshTokenAPI } from '../utils/api'; // Assuming refreshTokenAPI exists
import { setAccessToken, setRefreshToken, getAccessToken, getRefreshToken, removeTokens } from '../utils/auth'; // Adjust the import path as needed

interface AuthContextType {
    user: User | null;
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    refreshToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true); // Add loading state

    // Load user and tokens from localStorage on initial load
    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const storedAccessToken = getAccessToken();
                const storedRefreshToken = getRefreshToken();
                const storedUser = localStorage.getItem('user');

                if (storedAccessToken && storedUser && storedRefreshToken) {
                    setUser(JSON.parse(storedUser));
                    setIsAuthenticated(true);
                } else {
                    // If no tokens or user are found, force logout
                    logout();
                }
            } catch (error) {
                console.error("Failed to load auth data", error);
                logout(); // Force logout on error
            } finally {
                setLoading(false); // Set loading to false after the auth check
            }
        };

        initializeAuth();
    }, []);

    // Logout logic
    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
        removeTokens();
        localStorage.removeItem('user');
    };

    // Login logic
    const login = async (username: string, password: string) => {
        try {
            const { accessToken:accessToken, refreshToken:refreshToken, user: userData } = await loginAPI({ username, password });

            console.table(accessToken);
            console.table(refreshToken);
            console.table(user);
            setAccessToken(accessToken); // store accesstoken to database.
            setRefreshToken(refreshToken); // store refresh token to database.
            localStorage.setItem('user', JSON.stringify(userData));
            setUser(userData);
            setIsAuthenticated(true);
        } catch (error) {
            console.error("Login failed:", error);
            setIsAuthenticated(false);
            throw error;
        }
    };

    // Refresh token logic
    const refreshToken = async () => {
        try {
            const storedRefreshToken = getRefreshToken(); // Retrieve the refresh token from storage
            if (storedRefreshToken) {
                const { accessToken: newAccessToken } = await refreshTokenAPI(storedRefreshToken);
                setAccessToken(newAccessToken);
            } else {
                throw new Error("No refresh token available");
            }
        } catch (error) {
            console.error("Token refresh failed:", error);
            logout(); // Logout if refresh fails
        }
    };

    // Don't render children until loading is complete
    if (loading) {
        return <div>Loading...</div>; // Or any loading indicator
    }

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, login, logout, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
