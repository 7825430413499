import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser, faEnvelope, faUserPlus, faUsers, faFilePen, faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthProvider, useAuth } from './components/AuthContext';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';
import AddMemoPage from './pages/AddMemoPage';
import UserPage from './pages/UserPage';
import HomePage from './pages/HomePage';
import NavBar from './components/NavBar';
import NotFoundPage from './pages/NotFoundPage';
import Header from './components/Header';
import Footer from './components/Footer';
import DashBoardPage from './pages/Dashboard';
import ViewSenaraiMemoPage from './pages/ViewSenaraiMemoPage';
import EditMemoPage from './pages/EditMemoPage';
import AddUserPage from './pages/AddUserPage';
import ViewSenaraiPenggunaPage from './pages/ViewSenaraiPenggunaPage';
import ViewMyMemoPage from './pages/ViewMyMemoPage';
import EditUserPage from './pages/EditUserPage';

const ProtectedRoute: React.FC<{ role: string[]; children: JSX.Element }> = ({ role, children }) => {
  const { user, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!role.includes(user?.role!)) {
    return <p>You do not have permission to view this page.</p>;
  }

  return children;
};

const App: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [isHiddenNav, setIsHiddenNav] = useState(false);


  useEffect(()=>{
    if(isLoginPage){
      setIsHiddenNav(true);
    }else{
      setIsHiddenNav(false);
    }
  },[isLoginPage])


  const toggleNav = () => {
    const c_isNavOpen = isNavOpen
    setIsNavOpen(!c_isNavOpen);
    setIsHiddenNav(c_isNavOpen);
  }

  const loginPageHandler = (islogin: boolean) => {
    setIsLoginPage(islogin);
  }

  // Function to hide the nav
  const handleNavHide = () => setIsNavOpen(false);

  useEffect(() => {
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.style.marginLeft = isNavOpen ? '100rem' : '0rem'; // Adjust based on the width of your sidebar
    }
  }, [isNavOpen, isLoginPage]);

  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />

          <div className="flex flex-grow">
            {/* Sidebar/NavBar */}
            <aside
              id="nav-content"
              className={`${(isLoginPage || !isNavOpen || isHiddenNav) ? 'hidden' : 'flex flex-1'} lg:w-64 ${(isLoginPage) ? '' : 'lg:flex'}  bg-gray-200`}
            >
              <NavBar isHiddenNav={isHiddenNav} handleNavBarHide={handleNavHide} loginPageHander={loginPageHandler} />
            </aside>

            <div className="flex-grow flex flex-col">

              <button
                className={`${isLoginPage ? 'hidden' : 'lg:hidden'} lg:hidden  lg:flex-1 fixed bottom-10 right-4 z-40 w-10 h-10 bg-gray-800 text-white rounded-full`}
                onClick={toggleNav}
                aria-label="Toggle Navigation"
              >
                <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} />
              </button>

              <main id="main-content" className={`${isNavOpen ? 'sm:hidden' : ''} flex h-full p-0 bg-gray-100 ${isLoginPage ? '' : ' lg:p-4'}`}>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN']}>
                        <AdminPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/viewusers"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN']}>
                        <ViewSenaraiPenggunaPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/adduser"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN']}>
                        <AddUserPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/edituser/:id"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN']}>
                        <EditUserPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/data-only"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'DATA-ONLY']}>
                        <AddMemoPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/mymemo"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'DATA-ONLY', 'USER']}>
                        <ViewMyMemoPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/memo/addmemo"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'DATA-ONLY']}>
                        <AddMemoPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/memo/viewmemos"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'DATA-ONLY']}>
                        <ViewSenaraiMemoPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/editmemo/:memoId"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'DATA-ONLY']}>
                        <EditMemoPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/user"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'DATA-ONLY', 'USER']}>
                        <UserPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute role={['SUPER-ADMIN', 'SYSTEM-ADMIN', 'ADMIN', 'DATA-ONLY', 'USER']}>
                        <DashBoardPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/" element={<HomePage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </main>
            </div>
          </div>

          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
