// src/utils/auth.ts

// Set access token in localStorage
export const setAccessToken = (token: string) => {
    localStorage.setItem('accessToken', token);
};

// Get access token from localStorage
export const getAccessToken = (): string | null => {
    return localStorage.getItem('accessToken');
};

// Set refresh token in localStorage
export const setRefreshToken = (token: string) => {
    localStorage.setItem('refreshToken', token);
};

// Get refresh token from localStorage
export const getRefreshToken = (): string | null => {
    return localStorage.getItem('refreshToken');
};

// Remove tokens from localStorage (for example, on logout)
export const removeTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};
