import React from 'react';
import { useAuth } from '../components/AuthContext';

const NotFoundPage = () => {
    return (
        <div className='max-h-fit'>
            <h1>404 PAGE NOT FOUND</h1>
        </div>
    );
};

export default NotFoundPage;
