import React, { useEffect, useState } from 'react';
import { getMyMemos } from '../utils/api'; // Import API functions

import { Memo } from '../types/Memo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingLottieAnimation from '../components/LoadingLottieAnimation';
import LoadingMemosLottieAnimation from '../components/LoadingMemosLottieAnimation';
import ViewUserMemoRow from '../components/ViewUserMemoRow';

const ViewMyMemoPage: React.FC = () => {
    const [memos, setMemos] = useState<Memo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch memos from the server
    const loadMemos = async () => {
        setLoading(true);
        setError(null);
        try {
            const fetchedMemos = await getMyMemos(); // API call to fetch memos
            setMemos(fetchedMemos.memos);
        } catch (error) {
            setError('Failed to load memos');
            toast.error('Failed to load memos');
        } finally {
            setLoading(false);
        }
    };

    // Fetch memos on component mount
    useEffect(() => {
        loadMemos();
    }, []);

    if (loading) return (
        <div className='flex flex-1 items-center justify-center min-h-full flex-col'>

            {/* Loading Animation Container */}
            <div className='flex items-center justify-center h-[200px] w-[200px] mb-4'>
                <LoadingMemosLottieAnimation width={200} height={200} />
            </div>
            {/* Loading Message */}
            <p className='text-lg text-gray-600 font-semibold'>
                Please wait...
            </p>
        </div>
    );
    if (error) return <div>{error}</div>;

    return (
        <div className="w-full sm:mx-0 md:mx-4">
            <h1 className="text-lg font-bold mb-2">My Memo</h1>

            <div className="w-full mx-auto border border-gray-200 min-h-full rounded-lg sm:py-2 sm:px-2 py-4 px-4 shadow-lg">
                <div className="flex flex-wrap gap-x-4 sm:gap-y-1 sm:gap-x-4 md:gap-4 lg:gap-4 justify-center">
                    {memos.length > 0 ? (
                        memos.map(memo => (
                            <div className=" min-w-[150px] max-w-[150px] flex" key={memo.id}>
                                <ViewUserMemoRow className="min-w-[150px] max-w-[150px] flex" memo={memo} />
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-600 w-full">Nothing to view.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewMyMemoPage;
