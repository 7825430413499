import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUser, faEnvelope, faUserPlus, faUsers, faFilePen, faPlus, faList } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './AuthContext';

interface NavBarPageProps {
    showNavBar?: (islogin: boolean) => void;
    handleNavBarHide: () => void;
    isHiddenNav: boolean
    loginPageHander: (isLoginPage: boolean) => void;
}

const NavBar: React.FC<NavBarPageProps> = ({ handleNavBarHide, showNavBar, isHiddenNav, loginPageHander }) => {
    const { user, isAuthenticated } = useAuth();
    const [role, setRole] = useState('USER');

    useEffect(() => {
        setRole(user?.role!)
    }, [user]);

    useEffect(() => {
        loginPageHander(false);
    }, [user]);


    const handleLinkClick = () => {
        if (!isHiddenNav) {
            handleNavBarHide();
        }
    };

    //setIsSetHidden(isHidden);

    if (!isAuthenticated) {
        loginPageHander(true);
        return null;
    }


    return (
        <nav className={`"flex flex-col items-center lg:items-start w-64 h-full p-4 space-y-6"`}>
            {/* Dashboard Link */}
            <Link
                to="/dashboard"
                className="flex items-center p-2 hover:bg-gray-300 rounded w-full"
                aria-label="Dashboard"
                onClick={handleLinkClick}
            >
                <FontAwesomeIcon icon={faTachometerAlt} className="text-lg" />
                <span className="ml-4">Dashboard</span>
            </Link>

            {/* My Memo Link */}
            <Link
                to="/mymemo"
                className="flex items-center p-2 hover:bg-gray-300 rounded w-full"
                aria-label="My Memo"
                onClick={handleLinkClick}
            >
                <FontAwesomeIcon icon={faFilePen} className="text-lg" />
                <span className="ml-4">My Memo</span>
            </Link>

            {/* Admin Links */}
            {(role === 'ADMIN' || role === 'SUPER-ADMIN' || role === 'SYSTEM-ADMIN') && (
                <>
                    <div className="font-semibold">Admin</div>
                    <ul className="space-y-2 ml-4">
                        <li>
                            <Link
                                to="/admin/viewusers"
                                className="flex items-center p-2 hover:bg-gray-300 rounded w-full"
                                aria-label="View Users"
                                onClick={handleLinkClick}
                            >
                                <FontAwesomeIcon icon={faUsers} className="text-lg" />
                                <span className="ml-4">Senarai Pengguna</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/admin/adduser"
                                className="flex items-center p-2 hover:bg-gray-300 rounded w-full"
                                aria-label="Add User"
                                onClick={handleLinkClick}
                            >
                                <FontAwesomeIcon icon={faUserPlus} className="text-lg" />
                                <span className="ml-4">Tambah Pengguna</span>
                            </Link>
                        </li>
                    </ul>
                </>
            )}

            {/* Memo Links */}
            {(role === 'ADMIN' || role === 'SUPER-ADMIN' || role === 'SYSTEM-ADMIN' || role === 'DATA-ONLY') && (
                <>
                    <div className="font-semibold">Memo</div>
                    <ul className="space-y-2 ml-4">
                        <li>
                            <Link
                                to="/memo/viewmemos"
                                className="flex items-center p-2 hover:bg-gray-300 rounded w-full"
                                aria-label="View Memo"
                                onClick={handleLinkClick}
                            >
                                <FontAwesomeIcon icon={faList} className="text-lg" />
                                <span className="ml-4">Senarai Memo</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/memo/addmemo"
                                className="flex items-center p-2 hover:bg-gray-300 rounded w-full"
                                aria-label="Add Memo"
                                onClick={handleLinkClick}
                            >
                                <FontAwesomeIcon icon={faPlus} className="text-lg" />
                                <span className="ml-4">Tambah Memo</span>
                            </Link>
                        </li>
                    </ul>
                </>
            )}

            {/* Profile Link */}
            {/* {role === 'USER' && (
                <Link
                    to="/user/viewprofile"
                    className="flex items-center p-2 hover:bg-gray-300 rounded w-full"
                    aria-label="Profile"
                    onClick={handleLinkClick}
                >
                    <FontAwesomeIcon icon={faUser} className="text-lg" />
                    <span className="ml-4">Profile</span>
                </Link>
            )} */}
        </nav>
    );
};

export default NavBar;
