import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEdit } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { getJawatanOptions, getRolesOptions, getUserDetails, updateUserDetails } from '../utils/api'; // Adjust the API functions as needed
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { User } from '../types/user';
import { useParams } from 'react-router-dom';

// Spinner component
const Spinner = () => (
    <div className="flex justify-center items-center">
        <div className="w-6 h-6 border-4 border-t-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
    </div>
);

interface Option {
    label: string;
    value: number;
}



export default function EditUserPage() {
    const { id } = useParams<{ id: string }>(); // Get memoId from URL params
    const [userDetails, setUserDetails] = useState<User | null>(null);
    const [jawatanOptions, setJawatanOptions] = useState<Option[]>([]);
    const [selectedJawatan, setSelectedJawatan] = useState<Option | null>(null);
    const [rolesOptions, setRolesOptions] = useState<Option[]>([]);
    const [selectedRole, setSelectedRole] = useState<Option | null>(null);
    const [loadingJawatan, setLoadingJawatan] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loadingUser, setIsLoadingUser] = useState(true);
    const [error, setError] = useState<string | null>(null);



    // Fetch jawatan options
    useEffect(() => {
        const fetchJawatanOptions = async () => {
            setLoadingJawatan(true);
            try {
                const response = await getJawatanOptions();
                const options = response.map((item: any) => ({
                    label: item.nama_jawatan,
                    value: item.id,
                }));
                setJawatanOptions(options);
            } catch (error) {
                console.error('Failed to fetch jawatan options', error);
            } finally {
                setLoadingJawatan(false);
            }
        };

        fetchJawatanOptions();
    }, []);

    // Fetch roles options
    useEffect(() => {
        const fetchRolesOptions = async () => {
            setLoadingRoles(true);
            try {
                const response = await getRolesOptions();
                const options = response.map((item: any) => ({
                    label: item.user_role,
                    value: item.id,
                }));
                setRolesOptions(options);
            } catch (error) {
                console.error('Failed to fetch roles options', error);
            } finally {
                setLoadingRoles(false);
            }
        };

        fetchRolesOptions();
    }, []);

    // Fetch user details for editing
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                setIsLoadingUser(true);
                const userId = Number(id);
                const user = await getUserDetails(userId);
                setUserDetails(user);
                console.log(user);
                setSelectedRole({ label: user?.role!, value: user?.id_role! }); // Adjust according to your response structure
                setSelectedJawatan({ label: user?.jawatan!, value: user?.id_jawatan! });
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Failed to fetch user details', error.message);
                }else{
                    setError("Failed to fetch user details: Something went wrong.");
                    console.error('Failed to fetch user details', error);
                }
            } finally {
                setIsLoadingUser(false);
            }
        };

        if(jawatanOptions && rolesOptions)
        fetchUserDetails();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitting(true);

        try {
            await updateUserDetails({
                id: Number(id),
                nokp: userDetails!.nokp,
                nama: userDetails!.nama,
                nama_pendek: userDetails!.nama_pendek,
                id_jawatan: selectedJawatan?.value ?? 9,
                //jawatan: userDetails!.jawatan,
                gred_jawatan: userDetails!.gred_jawatan,
                telegram_id: userDetails!.telegram_id ?? undefined,
                id_role: selectedRole?.value ?? 4, // Default role ID if not selected
                jantina: userDetails?.jantina ?? "U"
            });
            toast.success('User updated successfully');
        } catch (error) {
            console.error('Failed to update user', error);
            toast.error('Failed to update user');
        } finally {
            setSubmitting(false);
        }
    };

    if (error) return <div>{error}</div>;

    if (loadingUser) return <div>Loading...</div>;

    if (!userDetails) return <div>User not found.</div>

    return (
        <div className="flex flex-1 justify-center items-center min-h-full p-4">
            <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg bg-white p-6 rounded-md shadow-md space-y-4"
            >
                <h2 className="text-2xl font-semibold mb-4">Edit User</h2>

                {/* NOKP */}
                <div className="relative">
                    <label htmlFor="nokp" className="block text-sm font-medium text-gray-700">NOKP</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="nokp"
                            type="text"
                            value={userDetails?.nokp}
                            onChange={(e) => setUserDetails({ ...userDetails!, nokp: e.target.value })}
                            placeholder="NOKP"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Nama */}
                <div className="relative">
                    <label htmlFor="nama" className="block text-sm font-medium text-gray-700">Nama</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faUser}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="nama"
                            type="text"
                            value={userDetails?.nama}
                            onChange={(e) => setUserDetails({ ...userDetails!, nama: e.target.value })}
                            placeholder="Nama"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Nama Pendek */}
                <div className="relative">
                    <label htmlFor="namaPendek" className="block text-sm font-medium text-gray-700">Nama Pendek</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="namaPendek"
                            type="text"
                            value={userDetails?.nama_pendek}
                            onChange={(e) => setUserDetails({ ...userDetails!, nama_pendek: e.target.value })}
                            placeholder="Nama Pendek"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Gender */}
                <div className="relative">
                    <label htmlFor="jantina" className="block text-sm font-medium text-gray-700">Jantina</label>
                    <div className="flex gap-4 mt-1">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="jantina"
                                value="L"
                                checked={userDetails!.jantina === 'L'}
                                onChange={(e) => setUserDetails({ ...userDetails!, jantina: e.target.value })}
                                className="form-radio text-indigo-600"
                                disabled={submitting}
                                required
                            />
                            <span className="ml-2 text-sm">Lelaki</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="jantina"
                                value="P"
                                checked={userDetails!.jantina === 'P'}
                                onChange={(e) => setUserDetails({ ...userDetails!, jantina: e.target.value })}
                                className="form-radio text-indigo-600"
                                disabled={submitting}
                                required
                            />
                            <span className="ml-2 text-sm">Perempuan</span>
                        </label>
                    </div>
                </div>

                {/* Jawatan */}
                <div className="relative">
                    <label htmlFor="jawatan" className="block text-sm font-medium text-gray-700">Jawatan</label>
                    <Select
                        id="jawatan"
                        options={jawatanOptions}
                        value={selectedJawatan}
                        onChange={(option) => setSelectedJawatan(option ?? null)}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select Jawatan..."
                        isLoading={loadingJawatan}
                        noOptionsMessage={() => (loadingJawatan ? "Loading..." : "No options")}
                        isDisabled={submitting}
                        required
                    />
                </div>

                {/* Role */}
                <div className="relative">
                    <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
                    <Select
                        id="role"
                        options={rolesOptions}
                        value={selectedRole}
                        onChange={(option) => setSelectedRole(option ?? null)}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select Role..."
                        isLoading={loadingRoles}
                        noOptionsMessage={() => (loadingRoles ? "Loading..." : "No options")}
                        isDisabled={submitting}
                        required
                    />
                </div>

                {/* Gred Jawatan */}
                <div className="relative">
                    <label htmlFor="gredJawatan" className="block text-sm font-medium text-gray-700">Gred Jawatan</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="gredJawatan"
                            type="text"
                            value={userDetails.gred_jawatan}
                            onChange={(e) => setUserDetails({ ...userDetails!, gred_jawatan: e.target.value })}
                            placeholder="Gred Jawatan"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Telegram ID */}
                <div className="relative">
                    <label htmlFor="telegramId" className="block text-sm font-medium text-gray-700">Telegram ID (optional)</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="telegramId"
                            type="number"
                            value={userDetails!.telegram_id ?? ''}
                            onChange={(e) => setUserDetails({ ...userDetails!, telegram_id: Number(e.target.value) })}
                            placeholder="Telegram ID"
                            className="mt-1 block w-full appearance-none no-spinner px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Submit button */}
                <div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400"
                        disabled={submitting}
                    >
                        {submitting ? <Spinner /> : 'Submit'}
                    </button>
                </div>
            </form>

            {/* Toast notifications */}
            <ToastContainer />
        </div>
    );
}
