import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEdit } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { getJawatanOptions, getRolesOptions, addUser } from '../utils/api'; // Assuming you have these API functions
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

// Spinner component
const Spinner = () => (
    <div className="flex justify-center items-center">
        <div className="w-6 h-6 border-4 border-t-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
    </div>
);

interface Option {
    label: string;
    value: number;
}

export default function AddUserPage() {
    const [nokp, setNokp] = useState('');
    const [nama, setNama] = useState('');
    const [namaPendek, setNamaPendek] = useState('');
    const [idJawatan, setIdJawatan] = useState<number | null>(null);
    const [gredJawatan, setGredJawatan] = useState('');
    const [telegramId, setTelegramId] = useState<number | null>(null);
    const [jantina, setJantina] = useState<'L' | 'P' | ''>(''); // Added state for gender
    const [jawatanOptions, setJawatanOptions] = useState<Option[]>([]);
    const [rolesOptions, setRolesOptions] = useState<Option[]>([]);
    const [selectedRole, setSelectedRole] = useState<Option | null>(null);
    const [loadingJawatan, setLoadingJawatan] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // Fetch jawatan options
    useEffect(() => {
        const fetchJawatanOptions = async () => {
            setLoadingJawatan(true);
            try {
                const response = await getJawatanOptions();

                const options = response.map((item: any) => ({
                    label: item.nama_jawatan,
                    value: item.id,
                }));
                setJawatanOptions(options);
            } catch (error) {
                console.error('Failed to fetch jawatan options', error);
            } finally {
                setLoadingJawatan(false);
            }
        };

        fetchJawatanOptions();
    }, []);

    // Fetch roles options
    useEffect(() => {
        const fetchRolesOptions = async () => {
            setLoadingRoles(true);
            try {
                const response = await getRolesOptions();
                const options = response.map((item: any) => ({
                    label: item.user_role,
                    value: item.id,
                }));
                setRolesOptions(options);
            } catch (error) {
                console.error('Failed to fetch roles options', error);
            } finally {
                setLoadingRoles(false);
            }
        };

        fetchRolesOptions();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitting(true);

        try {
            await addUser({
                nokp,
                nama,
                nama_pendek: namaPendek,
                id_jawatan: idJawatan!,
                gred_jawatan: gredJawatan,
                telegram_id: telegramId ?? null,
                id_role: selectedRole?.value ?? 4, // Default role ID if not selected
                jantina, // Added gender field
            });
            toast.success('User added successfully');
        } catch (error) {
            console.error('Failed to add user', error);
            toast.error('Failed to add user.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="flex flex-1 justify-center items-center min-h-full p-4">
            <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg bg-white p-6 rounded-md shadow-md space-y-4"
            >
                <h2 className="text-2xl font-semibold mb-4">Add User</h2>

                {/* NOKP */}
                <div className="relative">
                    <label htmlFor="nokp" className="block text-sm font-medium text-gray-700">NOKP</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="nokp"
                            type="text"
                            value={nokp}
                            onChange={(e) => setNokp(e.target.value)}
                            placeholder="NOKP"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Nama */}
                <div className="relative">
                    <label htmlFor="nama" className="block text-sm font-medium text-gray-700">Nama</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faUser}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="nama"
                            type="text"
                            value={nama}
                            onChange={(e) => setNama(e.target.value)}
                            placeholder="Nama"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Nama Pendek */}
                <div className="relative">
                    <label htmlFor="namaPendek" className="block text-sm font-medium text-gray-700">Nama Pendek</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="namaPendek"
                            type="text"
                            value={namaPendek}
                            onChange={(e) => setNamaPendek(e.target.value)}
                            placeholder="Nama Pendek"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Gender */}
                <div className="relative">
                    <label htmlFor="jantina" className="block text-sm font-medium text-gray-700">Jantina</label>
                    <div className="flex gap-4 mt-1">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="jantina"
                                value="L"
                                checked={jantina === 'L'}
                                onChange={() => setJantina('L')}
                                className="form-radio text-indigo-600"
                                disabled={submitting}
                            />
                            <span className="ml-2 text-sm">Lelaki</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="jantina"
                                value="P"
                                checked={jantina === 'P'}
                                onChange={() => setJantina('P')}
                                className="form-radio text-indigo-600"
                                disabled={submitting}
                            />
                            <span className="ml-2 text-sm">Perempuan</span>
                        </label>
                    </div>
                </div>

                {/* Jawatan */}
                <div className="relative">
                    <label htmlFor="jawatan" className="block text-sm font-medium text-gray-700">Jawatan</label>
                    <Select
                        id="jawatan"
                        options={jawatanOptions}
                        value={jawatanOptions.find(option => option.value === idJawatan)}
                        onChange={(option) => setIdJawatan(option?.value ?? null)}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select Jawatan..."
                        isLoading={loadingJawatan}
                        noOptionsMessage={() => (loadingJawatan ? "Loading..." : "No options")}
                        isDisabled={submitting}
                    />
                </div>

                {/* Role */}
                <div className="relative">
                    <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
                    <Select
                        id="role"
                        options={rolesOptions}
                        value={selectedRole}
                        onChange={(option) => setSelectedRole(option ?? null)}
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Select Role..."
                        isLoading={loadingRoles}
                        noOptionsMessage={() => (loadingRoles ? "Loading..." : "No options")}
                        isDisabled={submitting}
                    />
                </div>

                {/* Gred Jawatan */}
                <div className="relative">
                    <label htmlFor="gredJawatan" className="block text-sm font-medium text-gray-700">Gred Jawatan</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="gredJawatan"
                            type="text"
                            value={gredJawatan}
                            onChange={(e) => setGredJawatan(e.target.value)}
                            placeholder="Gred Jawatan"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting}
                        />
                    </div>
                </div>

                {/* Telegram ID */}
                <div className="relative">
                    <label htmlFor="telegramId" className="block text-sm font-medium text-gray-700">Telegram ID</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faUser}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="telegramId"
                            type="text"
                            value={telegramId ?? ''}
                            onChange={(e) => setTelegramId(e.target.value ? parseInt(e.target.value) : null)}
                            placeholder="Telegram ID"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            disabled={submitting}
                        />
                    </div>
                </div>


                <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${submitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={submitting}
                >
                    {submitting ? <Spinner /> : 'Add User'}
                </button>
            </form>
            <ToastContainer />
        </div>
    );
}
