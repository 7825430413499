import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faFilePdf, faEdit } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { getUserByShortName, addMemo } from '../utils/api';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify for notifications
import LoadingLottieAnimation from '../components/LoadingLottieAnimation';

// Spinner component (can be placed in a separate file)
const Spinner = () => (
    <div className="flex justify-center items-center">
        <div className="w-6 h-6 border-4 border-t-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
    </div>
);

interface UserOption {
    label: string;
    value: number;
}

export default function AddMemo() {
    const [title, setTitle] = useState('');
    const [receivedDate, setReceivedDate] = useState('');
    const [actionDate, setActionDate] = useState('');
    const [memoType, setMemoType] = useState('IN'); // Default to 'Masuk'
    const [file, setFile] = useState<File | null>(null);
    const [users, setUsers] = useState<UserOption[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UserOption[]>([]);
    const [searchParam, setSearchParam] = useState(''); // State for search parameter
    const [loading, setLoading] = useState(false); // State for loading indicator
    const [submitting, setSubmitting] = useState(false); // State for form submission loading


    // Set default date to today's date (Malaysia local time)
    useEffect(() => {
        const today = new Date().toLocaleDateString('en-CA', { timeZone: 'Asia/Kuala_Lumpur' });
        setReceivedDate(today);
        setActionDate(today);
    }, []);

    // Fetch users from the API and set the options for the multi-select
    useEffect(() => {
        const fetchUsers = async () => {
            if (!searchParam) return;
            if (searchParam.length < 3) return;

            setLoading(true); // Set loading to true when starting to fetch
            try {
                const response = await getUserByShortName(searchParam);
                const userOptions = response.map((user: any) => ({
                    label: user.s_name,
                    value: user.id,
                }));

                console.log(userOptions);
                setUsers(userOptions);
            } catch (error) {
                console.error("Failed to fetch users", error);
            } finally {
                setLoading(false); // Set loading to false when fetching is complete
            }
        };

        fetchUsers();
    }, [searchParam]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitting(true); // Show loading state

        const formData = new FormData();
        formData.append('memoData[tajuk]', title);
        formData.append('memoData[tarikh_terima]', receivedDate);
        formData.append('memoData[tarikh_tindakan]', actionDate);
        formData.append('memoData[jenis_memo]', memoType); // Add memo type
        if (file) {
            formData.append('file', file);
        }

        // Append recipients as individual fields
        selectedUsers.forEach((user, index) => {
            formData.append(`recipients[${index}][id_pengguna]`, user.value.toString());
        });

        try {
            const response = await addMemo(formData);
            console.log(':::::::::', formData);
            console.log('Memo added successfully', response);
            toast.success('Memo added successfully'); // Show success notification
        } catch (error) {
            console.error('Failed to add memo', error);
            toast.error('Failed to add memo'); // Show error notification
        } finally {
            setSubmitting(false); // Hide loading state after completion
        }
    };

    if (loading) {
        <LoadingLottieAnimation width={200} height={200} />
    }

    return (
        <div className="flex flex-1 justify-center items-center min-h-full p-4">
            <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg bg-white p-6 rounded-md shadow-md space-y-4"
            >
                <h2 className="text-2xl font-semibold mb-4">Add Memo</h2>

                {/* Title input */}
                <div className="relative">
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">Tajuk</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <textarea
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting} // Disable while submitting
                        />
                    </div>
                </div>

                {/* Received date input */}
                <div className="relative">
                    <label htmlFor="receivedDate" className="block text-sm font-medium text-gray-700">Tarikh Terima</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="receivedDate"
                            type="date"
                            value={receivedDate}
                            onChange={(e) => setReceivedDate(e.target.value)}
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting} // Disable while submitting
                        />
                    </div>
                </div>

                {/* Action date input */}
                <div className="relative">
                    <label htmlFor="actionDate" className="block text-sm font-medium text-gray-700">Tarikh Tindakan</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="actionDate"
                            type="date"
                            value={actionDate}
                            onChange={(e) => setActionDate(e.target.value)}
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                            disabled={submitting} // Disable while submitting
                        />
                    </div>
                </div>

                {/* Memo Type */}
                <div className="mb-4">
                    <span className="block text-gray-700">Jenis Memo</span>
                    <div className="mt-2 flex flex-col space-y-2">
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="radio"
                                name="memoType"
                                value="IN"
                                checked={memoType === 'IN'}
                                onChange={() => setMemoType('IN')}
                                className="form-radio h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                disabled={submitting} // Disable while submitting
                            />
                            <span className="ml-2 text-gray-700">Masuk</span>
                        </label>
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="radio"
                                name="memoType"
                                value="OUT"
                                checked={memoType === 'OUT'}
                                onChange={() => setMemoType('OUT')}
                                className="form-radio h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                                disabled={submitting} // Disable while submitting
                            />
                            <span className="ml-2 text-gray-700">Keluar</span>
                        </label>
                    </div>
                </div>

                {/* Select users */}
                <div className="relative">
                    <label htmlFor="users" className="block text-sm font-medium text-gray-700">Select Users</label>
                    <div className="relative">
                        <Select
                            id="users"
                            isMulti
                            options={users}
                            value={selectedUsers}
                            onChange={(selectedOptions) => setSelectedUsers(selectedOptions as UserOption[])}
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Select users..."
                            onInputChange={(inputValue) => setSearchParam(inputValue)}
                            isLoading={loading}
                            noOptionsMessage={() => (loading ? "Loading..." : "No options")}
                            isDisabled={submitting} // Disable while submitting
                        />
                    </div>
                </div>

                {/* Upload file */}
                <div className="relative">
                    <label htmlFor="file" className="block text-sm font-medium text-gray-700">Upload PDF</label>
                    <div className="relative">
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                        />
                        <input
                            id="file"
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => setFile(e.target.files?.[0] || null)}
                            className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            disabled={submitting} // Disable while submitting
                        />
                    </div>
                </div>

                {/* Submit button */}
                <div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400"
                        disabled={submitting} // Disable button while submitting
                    >
                        {submitting ? <Spinner /> : 'Submit'}
                    </button>
                </div>
            </form>

            {/* Toast notifications */}
            <ToastContainer />
        </div>
    );
}
