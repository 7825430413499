// src/pages/LoginPage.tsx
import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useAuth } from '../components/AuthContext'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

// Load environment variables
const APP_NAME = process.env.APP_NAME || 'App Name';
const APP_VERSION = process.env.REACT_APP_VERSION || '1.0.0';


const Login: React.FC = () => {
    const { login } = useAuth(); // Use the login function from AuthContext
    const navigate = useNavigate(); // Initialize useNavigate
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        
    },[]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            await login(username, password);
            navigate('/dashboard'); // Redirect to the desired route on successful login
        } catch (err: unknown) {
            handleError(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleError = (error: unknown) => {
        if (error instanceof AxiosError) {
            if (!error.response) {
                setError('Network error. Please check your connection and try again.');
            } else {
                const { status, data } = error.response;
                console.error('Response code:', status);
                console.error('Response content:', data);
                setError(`Error ${status}: ${data?.message || 'An unexpected error occurred. Please try again.'}`);
            }
        } else if (error instanceof Error) {
            setError(error.message || 'An unexpected error occurred. Please try again.');
        } else {
            setError('An unexpected error occurred. Please try again.');
        }
    };

    return (
        <div className="flex flex-col min-h-screen min-w-full bg-gradient-to-r from-purple-700 via-pink-500 to-red-500">
            <header className="absolute top-4 left-4 flex items-center space-x-3">
                <img
                    src="/app-logo.png"
                    alt="App Logo"
                    className="h-6"
                />
            </header>

            <main className="flex flex-1 items-center justify-center p-4">
                <div className="w-full max-w-sm p-8 bg-white shadow-md rounded-lg">
                    <h2 className="text-2xl font-semibold mb-4 text-blue-600 text-center">Login</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {error && (
                            <div className="mb-4 p-2 bg-red-100 border border-red-300 text-red-600 rounded">
                                {error}
                            </div>
                        )}
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                Username
                            </label>
                            <div className="relative">
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                                />
                                <input
                                    id="username"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Username"
                                    className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="relative">
                                <FontAwesomeIcon
                                    icon={faLock}
                                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm"
                                />
                                <input
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                    className="mt-1 block w-full px-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>
                    <div className="mt-4 text-center text-gray-500 text-sm">
                        App Version: {APP_VERSION}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Login;
