import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../static/memos-loading-animation.json'; // Replace with the path to your animation JSON file

interface LoadingLottieAnimationProps{
    height:number;
    width:number
}

const LoadingMemosLottieAnimation: React.FC<LoadingLottieAnimationProps> = ({height, width}) => {
    return (
        <div className='flex justify-center'>
            <Player 
                
                autoplay
                loop
                src={animationData}
                style={{ height: `${height}px`, width: `${width}px`,  }}
            />
        </div>
    );
};

export default LoadingMemosLottieAnimation;
