import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faTasks, faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { fetchFileBlob } from '../utils/api';
import { Memo } from '../types/Memo';
import MemoModal from './MemoModal'; // Import the modal component
import 'react-toastify/dist/ReactToastify.css';
import NoMemoErrorLottieAnimation from './NoMemoErrorLottieAnimation';

interface ViewMemoRowProps {
    memo: Memo;
    className?: string;
}

const formatDate = (date?: Date): string => {
    if (!date) return "INVALID DATE";
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const ViewUserMemoRow: React.FC<ViewMemoRowProps> = ({ memo, className }) => {
    const [blobUrls, setBlobUrls] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0); // Track current image index
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [imgDimensions, setImgDimensions] = useState<{ width: number, height: number } | null>(null);



    // Determine the border color based on jenis_memo
    const borderColor = memo.jenis_memo === 'IN' ? 'border-red-500' : 'border-green-500';


    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            setError(null);

            try {
                if (memo.png_urls.length > 0) {
                    const url = memo.png_urls[0];
                    const blobUrl = await fetchFileBlob(url);
                    setBlobUrls([blobUrl]); // Update state with just the single blob URL
                }
            } catch (error) {
                setError('Failed to load images');
            } finally {
                setLoading(false);
            }
        };

        fetchImages();

        return () => {
            blobUrls.forEach((url) => URL.revokeObjectURL(url));
            setBlobUrls([]);
        };
    }, [memo.png_urls]);

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const { width, height } = event.currentTarget;
        setImgDimensions({ width, height });
    };

    const handleImageError = () => {
        setError('Image failed to load');
    };

    const overlayTitle = memo.tajuk.split(' ').length > 10
        ? memo.tajuk.split(' ').slice(0, 10).join(' ') + '...'
        : memo.tajuk;

    const closeModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    const openModal = () => {
        if (blobUrls.length > 0) {
            setIsModalOpen(true);
            setCurrentIndex(0); // Reset to the first image in the modal
        }
    };

    const handleNext = () => {
        if (currentIndex < memo.png_urls.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };


    const handleDownloadPdf = async () => {
        try {

            console.log(memo.pdf_url);
            // Fetch the PDF Blob from the server using the memo.pdf_url
            const blobUrl = await fetchFileBlob(memo.pdf_url);

            // Create a temporary anchor element to download the PDF
            const a = document.createElement('a');
            a.href = blobUrl; // Set the Blob URL
            a.download = 'memo.pdf'; // The name of the file to be downloaded (you can change the file name)

            // Trigger the download
            document.body.appendChild(a);
            a.click();

            // Clean up by removing the anchor element and revoking the Blob URL
            document.body.removeChild(a);
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Failed to download PDF', error);
        }
    };


    return (
        <>
            <div className={`relative flex flex-col bg-white rounded-xl mb-2 shadow-md overflow-hidden border-2 ${borderColor} ${className}`}>
                {/* Image with overlay title */}
                <div className={`flex justify-center items-center ${loading ? '' : 'cursor-pointer'} relative`}

                    onClick={() => openModal()} // Open modal on click and pass index
                >
                    {loading ? (
                        <div className="flex items-center justify-center min-h-[211px] max-h[211px] min-w-[150px] max-w-[150px] bg-gray-200">
                            <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                        </div>
                    ) : error ? (
                        <div className="flex items-center justify-center min-h-[211px] max-h[211px] min-w-[150px] max-w-[150px] bg-gray-200"
                        //onClick={() => openModal()} // Open modal on click and pass index
                        >
                            <NoMemoErrorLottieAnimation width={50} height={50} />
                        </div>
                    ) : blobUrls[0] ? (
                        <>
                            <img
                                src={blobUrls[0]}
                                alt="Memo preview"
                                className="rounded-lg object-cover cursor-pointer w-fit"
                                style={{ width: '150px', height: '211px', maxWidth: '150px', minWidth: '150px', minHeight: '211px', maxHeight: '211px' }} // A4 ratio
                                onClick={() => openModal()} // Open modal on click and pass index
                                onLoad={handleImageLoad}
                                onError={handleImageError}
                            />
                            {/* Overlay title with opacity effect */}
                            <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-center">
                                {overlayTitle}
                                {/* Tarikh Terima and Tarikh Tindakan */}
                                <div className="p-2 mt-0 text-center">
                                    <span className="text-sm flex items-center justify-center rounded-md text-white bg-green-600 p-x-4 p-y-2 mb-2">
                                        <FontAwesomeIcon icon={faInbox} className="mr-2" />
                                        {formatDate(memo.tarikh_terima!) || 'N/A'}
                                    </span>
                                    <span className="text-sm flex items-center justify-center rounded-md text-white bg-red-600 p-x-4 p-y-2">
                                        <FontAwesomeIcon icon={faTasks} className="mr-2" />
                                        {formatDate(memo.tarikh_tindakan!) || 'N/A'}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex items-center justify-center bg-gray-200 rounded-b-xl">
                            <span className="text-gray-500">No image available</span>
                            {/* Tarikh Terima and Tarikh Tindakan */}
                            <div className="p-2 mt-2 text-center">
                                <div className="p-2 mt-0 text-center">
                                    <span className="text-sm flex items-center justify-center rounded-md text-white bg-green-600 p-x-4 p-y-2 mb-2">
                                        <FontAwesomeIcon icon={faInbox} className="mr-2" />
                                        {formatDate(memo.tarikh_terima!) || 'N/A'}
                                    </span>
                                    <span className="text-sm flex items-center justify-center rounded-md text-white bg-red-600 p-x-4 p-y-2">
                                        <FontAwesomeIcon icon={faTasks} className="mr-2" />
                                        {formatDate(memo.tarikh_tindakan!) || 'N/A'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>


            </div>

            {/* Modal component */}
            {isModalOpen && (
                <MemoModal
                    onClose={closeModal}
                    imageUrls={memo.png_urls}
                    currentIndex={currentIndex}
                    onNext={handleNext}
                    onPrev={handlePrev}
                    onDownloadPdf={handleDownloadPdf}
                />
            )}
        </>
    );
};

export default ViewUserMemoRow;
