import React from 'react';
import LoadingLottieAnimation from './LoadingLottieAnimation';
import LoadingStatisticalLottieAnimation from './LoadingStatisticalLottieAnimation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

interface StatisticsCardProps {
    title: string;
    value: number | string | undefined;
    bgColor: string;
    isLoading: boolean;
    className?: string;
    isError?: boolean;
    handleError?: () => void;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({ title, value, bgColor, isLoading, className, isError, handleError }) => {

    if (isLoading) return (
        <div className={`rounded-lg p-6 ${className} bg-gradient-to-r from-gray-100 to-gray-200 shadow-lg`}>
            <LoadingStatisticalLottieAnimation width={50} height={50} />
        </div>
    )

    if (isError) return (
        <div className={`rounded-lg p-6 ${className} bg-gradient-to-r from-gray-100 to-gray-200 shadow-lg`}>
            <div className='flex flex-1 items-center justify-center min-h-full flex-col'
            onClick={handleError}>
                <div className='flex flex-col items-center justify-center h-full mb-4 bg-white rounded-2xl'>
                    <FontAwesomeIcon icon={faRefresh} className='text-3xl text-gray-600 mb-2' />
                    <p className="text-gray-600">Tap/Click to retry</p>
                </div>
            </div>
        </div>
    )

    return (
        <div className={`rounded-lg p-6 ${className} bg-gradient-to-r ${bgColor} shadow-lg`}>
            <div className="flex flex-col items-center text-center h-full justify-center space-y-4">
                <h2 className="text-lg font-semibold text-gray-500 tracking-wide">{title}</h2>
                <p className="text-4xl font-extrabold text-gray-800">{value}</p>
            </div>
        </div>
    );
};

export default StatisticsCard;
