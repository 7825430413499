import React, { useEffect, useState } from 'react';
import { fetchFileBlob } from '../utils/api'; // Adjust the path based on your project structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes, faFilePdf, faRedo } from '@fortawesome/free-solid-svg-icons';
import LoadingMemosLottieAnimation from './LoadingMemosLottieAnimation';
import NoMemoErrorLottieAnimation from './NoMemoErrorLottieAnimation';

interface ModalProps {
    onClose: () => void;
    imageUrls: string[]; // Array of image URLs (private image endpoints)
    currentIndex: number;
    onNext: () => void;
    onPrev: () => void;
    onDownloadPdf?: () => void; 
}

const MemoModal: React.FC<ModalProps> = ({ onClose, imageUrls, currentIndex, onNext, onPrev, onDownloadPdf }) => {
    const [blobUrls, setBlobUrls] = useState<string[]>([]); // To store Blob URLs for images
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchImages = async () => {
        setLoading(true);
        setError(null);

        try {
            const fetchedBlobUrls = await Promise.all(
                imageUrls.map(async (url) => await fetchFileBlob(url))
            );
            setBlobUrls(fetchedBlobUrls);
        } catch (error) {
            setError('Failed to load images');
        } finally {
            setLoading(false);
        }
    };

    // Fetch the image blobs using fetchImageBlob
    useEffect(() => {
        fetchImages();

        // Clean up Blob URLs when modal is closed or images change
        return () => {
            blobUrls.forEach((url) => URL.revokeObjectURL(url));
            setBlobUrls([]);
        };
    }, [imageUrls]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="relativerounded-lg overflow-hidden shadow-lg max-w-screen-lg w-full max-h-screen">

                <div className="relative flex items-center justify-center p-2">
                    {/* Error message and retry button */}
                    {error ? (
                        <div className="flex flex-col items-center justify-center bg-white  min-w-[150px] min-h-[150px] rounded-sm p-4">
                            <p className="text-red-500 mb-4 text-center">{error}</p>
                            <div className="flex items-center justify-center min-h-[211px] max-h[211px] min-w-[150px] max-w-[150px] bg-gray-200 mb-4"

                            >
                                <NoMemoErrorLottieAnimation width={50} height={50} />
                            </div>
                            {/* Buttons Container */}
                            <div className="flex w-full space-x-4">
                                {/* Retry Button */}
                                <button
                                    onClick={fetchImages}
                                    className="flex-1 text-blue-500 bg-white border border-blue-500 h-12 flex items-center justify-center px-4 py-2 rounded hover:bg-gray-200"
                                >
                                    <FontAwesomeIcon icon={faRedo} size="lg" />
                                    <span className="ml-2">Retry</span>
                                </button>

                                {/* Close Button */}
                                <button
                                    onClick={onClose}
                                    className="flex-1 text-red-600 bg-white border border-red-600 h-12 flex items-center justify-center px-4 py-2 rounded hover:bg-gray-200"
                                >
                                    <FontAwesomeIcon icon={faTimes} size="lg" />
                                    <span className="ml-2">Close</span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* Loading Spinner */}
                            {loading ? (
                                <div className="flex items-center justify-center bg-white min-w-[150px] min-h-[150px] md:min-w-[200px] md:min-h-[200px] md:max-w-[200px] md:max-h-[200px] lg:min-w-[30vw] lg:min-h-[80vh] lg:max-w-[30vw] lg:max-h-[80vh]">
                                    <div className="flex flex-col items-center justify-center">
                                        <div className="mb-2">
                                            <LoadingMemosLottieAnimation width={100} height={100} />
                                        </div>
                                        <p className="text-gray-600 text-sm font-semibold">Please wait...</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="relative flex items-center justify-center max-w-[210mm] max-h-[297mm] sm:max-w-[100vw] sm:max-h-[100vh]">
                                    {/* Close Button */}
                                    <button
                                        onClick={onClose}
                                        className="absolute top-4 right-4 text-gray-800 bg-white h-12 w-12 hover:bg-gray-600 rounded-full p-3"
                                        style={{ zIndex: 1000 }}
                                    >
                                        <FontAwesomeIcon icon={faTimes} size="lg" />
                                    </button>

                                    {/* Previous Button */}
                                    <button
                                        onClick={onPrev}
                                        disabled={currentIndex === 0}
                                        className={`absolute left-2 z-50 text-gray-800 bg-transparent rounded-full h-12 w-12 p-4 transition-transform transform ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl hover:scale-110'}`}
                                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                                    </button>

                                    {/* Image Display */}
                                    <img src={blobUrls[currentIndex]} alt="Preview" className="w-auto h-auto max-w-full max-h-[80vh] m-x-1 object-contain" />

                                    {/* Next Button */}
                                    <button
                                        onClick={onNext}
                                        disabled={currentIndex === blobUrls.length - 1}
                                        className={`absolute right-2 z-50 text-gray-800 bg-transparent rounded-full h-12 w-12 p-4 transition-transform transform ${currentIndex === blobUrls.length - 1 ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl hover:scale-110'}`}
                                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                    </button>
                                    {/* Download Button */}
                                    <button
                                        onClick={onDownloadPdf} // This opens the image in a new tab where it can be downloaded
                                        className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 z-50 text-white bg-red-500 border border-red-700 rounded-md w-auto px-4 py-1 transition-transform hover:shadow-xl hover:scale-110`}
                                    >
                                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                        <span className="ml-2">Download</span>
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MemoModal;
