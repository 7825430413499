import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './AuthContext'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
    const { logout, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    if(!isAuthenticated) return null;

    return (
        <header className="bg-gray-800 text-white flex w-full justify-between items-center p-4 shadow-lg">
            {/* Logo Section */}
            <div className="flex items-center">
                <img
                    src="/app-logo.png" // Replace with your logo path
                    alt="App Logo"
                    className="h-8 w-auto mr-3"
                />
                {/* <span className="hidden sm:inline text-xl font-semibold">e Tell Us</span> Add app name if desired */}
            </div>

            {/* Profile and Logout Section */}
            <div className="flex items-center space-x-4">
                {/* Profile Button */}
                {/* <button className="flex items-center space-x-2 hover:bg-gray-700 px-3 py-2 rounded-md focus:outline-none">
                    <FontAwesomeIcon icon={faUser} />
                    <span className='hidden sm:inline'>Profile</span>
                </button> */}

                {/* Logout Button */}
                <button
                    className="flex items-center space-x-2 hover:bg-red-600 px-3 py-2 rounded-md focus:outline-none"
                    onClick={handleLogout}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    <span className='hidden sm:inline'>Logout</span>
                </button>
            </div>
        </header>
    );
};

export default Header;
