import React, { useEffect, useState } from 'react';
import { getMemos, deleteMemo } from '../utils/api'; // Import API functions
import ViewMemoRowComponent from '../components/ViewMemoRowComponent';
import { Memo } from '../types/Memo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingLottieAnimation from '../components/LoadingLottieAnimation';
import ErrorLottieAnimation from '../components/ErrorLottieAnimation';

const ViewSenaraiMemoPage: React.FC = () => {
    const [memos, setMemos] = useState<Memo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch memos from the server
    const loadMemos = async () => {
        setLoading(true);
        setError(null);
        try {
            const fetchedMemos = await getMemos(); // API call to fetch memos
            setMemos(fetchedMemos.memos);
        } catch (error) {
            setError('Failed to load memos');
            toast.error('Failed to load memos');
        } finally {
            setLoading(false);
        }
    };

    // Handle memo deletion
    const handleDeleteMemo = async (id: number) => {
        try {
            await deleteMemo(id); // API call to delete memo
            toast.success('Memo deleted successfully!');
            loadMemos(); // Refresh memos after deletion
        } catch (error) {
            toast.error('Failed to delete memo');
        }
    };

    // Fetch memos on component mount
    useEffect(() => {
        loadMemos();
    }, []);

    if (loading) return (
        <div className='flex flex-1 items-center justify-center min-h-full flex-col'>
            {/* Loading Animation Container */}
            <div className='flex items-center justify-center h-[200px] w-[200px] mb-4'>
                <LoadingLottieAnimation width={200} height={200} />
            </div>
            {/* Loading Message */}
            <p className='text-lg text-gray-600 font-semibold'>
                Please wait...
            </p>
        </div>
    );

    if (error) return (
        <div className='flex flex-1 items-center justify-center min-h-full flex-col'>
            {/* Loading Animation Container */}
            <div className='flex items-center justify-center h-[200px] w-[200px] mb-4'>
                <ErrorLottieAnimation width={250} height={250} />
            </div>
            {/* Loading Message */}
            <p className='text-lg text-gray-600 font-semibold'>
            {error}
            </p>
        </div>
    )

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-semibold mb-4">Memos</h1>
            {/* Display list of memos */}
            {memos.length > 0 ? (
                memos.map((memo) => (
                    <ViewMemoRowComponent
                        key={memo.id}
                        memo={memo}
                        onDelete={handleDeleteMemo}
                    />
                ))
            ) : (
                <div>No memos found.</div>
            )}
        </div>
    );
};
export default ViewSenaraiMemoPage;
