import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../static/no-profile-pic-boy.json'; // Replace with the path to your animation JSON file

interface NoProfilePictureLottieAnimationProps{
    height:number;
    width:number
}

const NoProfilePictureLottieAnimationBoy: React.FC<NoProfilePictureLottieAnimationProps> = ({height, width}) => {
    return (
        <div className='flex justify-center'>
            <Player 
                
                autoplay
                loop
                src={animationData}
                style={{ height: `${height}px`, width: `${width}px`,  }}
            />
        </div>
    );
};

export default NoProfilePictureLottieAnimationBoy;
