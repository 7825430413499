// src/utils/api.ts
import axios from 'axios';
import { setAccessToken, setRefreshToken, getRefreshToken, getAccessToken } from './auth';
import { User } from '../types/user';
import { LoginResponse, RefreshTokenResponse, SearchShortnameResponse } from '../types/api'; // Adjust the import path as needed
import { Memo } from '../types/Memo';

const apiClient = axios.create({
    baseURL: /* 'http://127.0.0.1:3001'  , */ 'https://api.u-tellus.app', // Set your API base URL here
    timeout: 60000, // 60 seconds timeout
});

const handleError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
        console.error('Axios error message:', error.message);
        console.error('Axios error code:', error.code);
        console.error('Axios error response:', error.response);

        if (error.response) {
            const { status, data } = error.response;
            console.error('Response status:', status);
            console.error('Response data:', data);
            return `${data?.error || 'An unexpected error occurred.'}`;
        } else {
            return 'Network error. Please check your connection and try again.';
        }
    } else {
        console.error('Unexpected error:', error);
        return 'An unexpected error occurred.';
    }
};

apiClient.interceptors.request.use((config) => {
    const token = getAccessToken(); // Adjust to use utility function
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = getRefreshToken();

                console.log("Refresh-Token[api.ts]:", refreshToken);
                if (!refreshToken) {
                    // Handle the case where no refresh token is available
                    return Promise.reject(error);
                }

                const { accessToken: accessToken, refreshToken: newRefreshToken } = await refreshTokenAPI(refreshToken); // Pass the refresh token to the API call

                console.log('newaccesstoken:', accessToken);
                console.log("newRefreshtoke:", newRefreshToken);

                setAccessToken(accessToken);
                setRefreshToken(newRefreshToken);
                apiClient.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

                return apiClient(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                // Handle token refresh failure (e.g., redirect to login)
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export const loginAPI = async (credentials: { username: string, password: string }) => {
    try {
        const response = await apiClient.post<LoginResponse>('/auth/login', credentials);
        return response.data;
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const refreshTokenAPI = async (refreshToken: string) => {
    try {
        const response = await apiClient.post<RefreshTokenResponse>('/auth/refresh', { refreshToken: refreshToken });
        return response.data;
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const getUserByShortName = async (search_param: string) => {
    try {
        const response = await apiClient.get<SearchShortnameResponse>('/user/searchShortName', {
            params: { q: search_param } // Pass query parameters here
        });
        return response.data.results;
    } catch (error) {
        throw new Error(handleError(error));
    }
};


export const addMemo = async (formData: FormData) => {
    try {
        const response = await apiClient.post('/memo', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure correct content type for file uploads
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(handleError(error)); // Use the handleError function for consistent error handling
    }
};

type JsonObject = {
    [key: string]: string | string[]; // Allow string or an array of strings
};


export const updateMemo = async (memo_id: number, memoData: any, recipients: any) => {
    //console.table(memoData);
    //console.table(recipients);
    try {
        //const response = await apiClient.patch(`/memo`);
        const memo_data = {
            ...memoData,
            id: memo_id
        }
        const response = await apiClient.patch(`/memo/`, { memoData: memo_data, recipients: recipients });
        return response.data;
    } catch (error) {
        throw new Error(handleError(error));
    }
};



interface GetMemosResponse {
    memos: Memo[];
    totalMemos: number;
}

export const getMemos = async (): Promise<GetMemosResponse> => {

    // await new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve(null)
    //     }, 2000);
    // });


    try {
        const response = await apiClient.get<GetMemosResponse>('/memo/');
        const data = response.data;

        // Parse date fields
        const memos = data.memos.map(memo => ({
            ...memo,
            tarikh_kemaskini: new Date(memo.tarikh_kemaskini),
            tarikh_tambah: new Date(memo.tarikh_tambah),
            tarikh_terima: new Date(memo.tarikh_terima),
            tarikh_tindakan: new Date(memo.tarikh_tindakan)
        }));

        return {
            ...data,
            memos
        };
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const getMyMemos = async (): Promise<GetMemosResponse> => {

    // await new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve(null)
    //     }, 2000);
    // });


    try {
        const response = await apiClient.get<GetMemosResponse>('/user/memos/');
        const data = response.data;

        // Parse date fields
        const memos = data.memos.map(memo => ({
            ...memo,
            tarikh_kemaskini: new Date(memo.tarikh_kemaskini),
            tarikh_tambah: new Date(memo.tarikh_tambah),
            tarikh_terima: new Date(memo.tarikh_terima),
            tarikh_tindakan: new Date(memo.tarikh_tindakan)
        }));

        return {
            ...data,
            memos
        };
    } catch (error) {
        throw new Error(handleError(error));
    }
};


export const getMemosByDate = async (date: string | null): Promise<GetMemosResponse> => {
    try {
        const response = await apiClient.get<GetMemosResponse>('/memo/');
        const data = response.data;

        // Parse date fields
        const memos = data.memos.map(memo => ({
            ...memo,
            tarikh_kemaskini: new Date(memo.tarikh_kemaskini),
            tarikh_tambah: new Date(memo.tarikh_tambah),
            tarikh_terima: new Date(memo.tarikh_terima),
            tarikh_tindakan: new Date(memo.tarikh_tindakan)
        }));

        return {
            ...data,
            memos
        };
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const getMemosByDateRange = async (start_date: string | null, end_date: string | null): Promise<GetMemosResponse> => {
    try {
        const response = await apiClient.get<GetMemosResponse>('/memo/');
        const data = response.data;

        // Parse date fields
        const memos = data.memos.map(memo => ({
            ...memo,
            tarikh_kemaskini: new Date(memo.tarikh_kemaskini),
            tarikh_tambah: new Date(memo.tarikh_tambah),
            tarikh_terima: new Date(memo.tarikh_terima),
            tarikh_tindakan: new Date(memo.tarikh_tindakan)
        }));

        return {
            ...data,
            memos
        };
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const getMemoById = async (memo_id: number): Promise<Memo> => {
    try {
        console.log('MEMO_ID', memo_id);
        const response = await apiClient.get<{ memo: Memo }>(`/memo/${memo_id}`);
        const data = response.data;

        // Parse date fields
        const memo = {
            ...data.memo,
            tarikh_kemaskini: new Date(data.memo.tarikh_kemaskini),
            tarikh_tambah: new Date(data.memo.tarikh_tambah),
            tarikh_terima: new Date(data.memo.tarikh_terima),
            tarikh_tindakan: new Date(data.memo.tarikh_tindakan)
        };

        return memo;
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const deleteMemo = async (memo_id: number): Promise<boolean> => {
    try {
        const response = await apiClient.delete<GetMemosResponse>(`/memo/${memo_id}`);

        return false;
    } catch (error) {
        throw new Error(handleError(error));
    }
};


interface GetUserResponse {
    users: User[];
    totalMemos: number;
}

export const getUsers = async (): Promise<GetUserResponse> => {
    try {
        const response = await apiClient.get<GetUserResponse>('/admin/users/');
        const data = response.data;

        // Parse date fields
        const users = data.users.map(user => ({
            ...user,
            tarikh_kemaskini: new Date(user.tarikh_kemaskini!),
            tarikh_tambah: new Date(user.tarikh_tambah!),
            tarikh_terima: new Date(user.tarikh_terima!),
            tarikh_tindakan: new Date(user.tarikh_tindakan!)
        }));

        return {
            ...data,
            users
        };
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const getUserDetails = async (user_id: number): Promise<User | null> => {
    try {
        const response = await apiClient.get<{ user: User }>(`/admin/user/${user_id}`);
        const data = response.data;

        console.log(data.user);
        // Parse date fields
        const user = {
            ...data.user,
            tarikh_kemaskini: new Date(data.user.tarikh_kemaskini!),
            tarikh_tambah: new Date(data.user.tarikh_tambah!),
            tarikh_terima: new Date(data.user.tarikh_terima!),
            tarikh_tindakan: new Date(data.user.tarikh_tindakan!)
        };

        return user

    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const updateUserDetails = async (user_details: User): Promise<void> => {
    try {
        const response = await apiClient.patch(`/admin/user/${user_details.id}`, { user: user_details });
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export const addUser = async (user: any): Promise<void> => {
    try {
        const response = await apiClient.post('/admin/user/register', { user: user });
    } catch (error) {
        throw new Error(handleError(error));
    }
};


export interface JawatanOption {
    id: number;
    kod_jawatan: string;
    nama_jawatan: string;
}

export const getJawatanOptions = async (): Promise<JawatanOption[]> => {
    try {
        const response = await apiClient.get<{ jawatan: JawatanOption[] }>('/admin/jawatan');
        const data = response.data;

        //console.log(data.jawatan);
        // // Transform the data into the format required for the Select component
        // const options = data.jawatan.map(jawatan => ({
        //     label: jawatan.nama_jawatan,
        //     value: jawatan.id
        // }));

        return data.jawatan;
    } catch (error) {
        throw new Error(handleError(error));
    }
};

export interface RoleOption {
    id: number;
    user_role: string;
    role_description?: string;
}

export const getRolesOptions = async (): Promise<RoleOption[]> => {
    try {
        const response = await apiClient.get<{ roles: RoleOption[] }>('/admin/roles');
        const data = response.data;

        // // Transform the data into the format required for the Select component
        // const options = data.roles.map(role => ({
        //     label: role.user_role,
        //     value: role.id
        // }));

        return data.roles;
    } catch (error) {
        throw new Error(handleError(error));
    }
};



export const fetchOverallMemoStatistic = async ():
    Promise<{
        inCount: number, outCount: number, monthlyData: {
            month: string,
            inCount: number,
            outCount: number,
        }[]
    }> => {


    try {
        // Simulate delay (e.g., 5 seconds)
        // await new Promise((resolve) => {
        //     setTimeout(() => {
        //         resolve(null)
        //     }, 2000);
        // });


        const response = await apiClient.get<{
            stats: {
                inCount: number, outCount: number, monthlyData: {
                    month: string,
                    inCount: number,
                    outCount: number,
                }[]
            }
        }>('/admin/memo/overall/stats');

        return response.data.stats;
    } catch (error) {
        throw new Error(handleError(error));
    }
};


export const fetchUserMemoStatistic = async (user_id: number):
    Promise<{
        inCount: number, outCount: number, monthlyData: {
            month: string,
            inCount: number,
            outCount: number,
        }[]
    }> => {

    try {
        // Simulate delay (e.g., 5 seconds)
        // await new Promise((resolve) => {
        //     setTimeout(() => {
        //         resolve(null)
        //     }, 2000);
        // });


        const response = await apiClient.get<{
            stats: {
                inCount: number, outCount: number, monthlyData: {
                    month: string,
                    inCount: number,
                    outCount: number,
                }[]
            }
        }>('/user/memo/stats');

        return response.data.stats;
    } catch (error) {
        throw new Error(handleError(error));
    }


};



export const deleteUser = async (user_id: number): Promise<void> => {
    try {
        const response = await apiClient.delete(`/admin/user/${user_id}`);
    } catch (error) {
        throw new Error(handleError(error));
    }
};



export const fetchFileBlob = async (url: string): Promise<string> => {
    try {
        console.log("FILE_URL:::::::::", url);

        // Fetch the file as a Blob from the API
        const response = await apiClient.get(url, {
            responseType: 'blob', // Fetch as Blob
        });

        // Determine the file type based on the URL or content type
        const contentType = response.headers['content-type'];

        let blobType = 'image/png'; // Default to image/png

        // Check if the file is a PDF
        if (contentType === 'application/pdf' || url.endsWith('.pdf')) {
            blobType = 'application/pdf';
        }

        // Create a new Blob using the appropriate MIME type
        const fileBlob = new Blob([response.data], { type: blobType });

        // Return Blob URL for display or download
        return URL.createObjectURL(fileBlob);

    } catch (error) {
        throw new Error('Failed to fetch file');
    }
};

